import React from 'react';

import { LandingPage } from './LandingPage';
import { initializeIcons } from './fabric-icons';

initializeIcons('./fonts/');

export const App = () => {
  return <LandingPage />;
};
