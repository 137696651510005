import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FontWeights,
  IButtonStyles,
  Icon,
  IconButton,
  IIconStyles,
  IStyle,
  mergeStyleSets,
  Stack,
  Text,
} from '@fluentui/react';

import { useIsSmallScreen } from './LandingPage/useIsSmallScreen';

export const suiteHeaderColor = '#001433';
export const suiteHeaderPressedColor = '#053385';
export const suiteHeaderTextColor = '#FFFFFF';
export const suiteHeaderHoverColor = '#03245C';

const selectedStyle: IStyle = {
  color: suiteHeaderTextColor,
  borderColor: suiteHeaderHoverColor,
  backgroundColor: suiteHeaderHoverColor,
};

const createHeaderButtonStyles = (styles?: IButtonStyles) => {
  return mergeStyleSets(
    {
      root: {
        color: suiteHeaderTextColor,
        backgroundColor: suiteHeaderColor,
        borderColor: suiteHeaderColor,
        width: 48,
        height: 48,
        minWidth: 48,
      },
      rootPressed: {
        color: suiteHeaderTextColor,
        borderColor: suiteHeaderPressedColor,
        backgroundColor: suiteHeaderPressedColor,
      },
      rootHovered: selectedStyle,
      rootDisabled: selectedStyle,
      rootExpanded: selectedStyle,
      iconDisabled: {
        color: suiteHeaderTextColor,
      },
    } as IButtonStyles,
    styles
  );
};

const headerIconStyles: IIconStyles = {
  root: {
    color: suiteHeaderTextColor,
    backgroundColor: suiteHeaderColor,
    borderColor: suiteHeaderColor,
    fontSize: 16,
  },
};

const styles = {
  root: {
    root: {
      backgroundColor: suiteHeaderColor,
      height: '48px',
    },
  },
  headerText: {
    root: {
      color: suiteHeaderTextColor,
      fontWeight: FontWeights.semibold,
    },
  },
  headerButton: createHeaderButtonStyles(),
  headerIcon: headerIconStyles,
};

export const Header = ({
  onBurgerButtonClick,
}: {
  onBurgerButtonClick: () => void;
}) => {
  const { t } = useTranslation();
  const { root, headerText, headerButton, headerIcon } = styles;
  const isSmallScreen = useIsSmallScreen();
  return (
    <Stack
      as="header"
      grow
      horizontal
      horizontalAlign="space-between"
      styles={root}
    >
      <Stack
        horizontal
        verticalFill
        verticalAlign="center"
        tokens={{ childrenGap: 10 }}
      >
        {isSmallScreen ? (
          <IconButton
            styles={headerButton}
            iconProps={{ iconName: 'GlobalNavButton' }}
            ariaLabel={t('Toggle navbar')}
            onClick={onBurgerButtonClick}
          />
        ) : (
          <Stack
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { width: 48, height: 48 } }}
          >
            <Icon styles={headerIcon} iconName={'WaffleOffice365'} />
          </Stack>
        )}
        <Text styles={headerText} variant="mediumPlus">
          Dynamics 365 Commerce
        </Text>
      </Stack>
    </Stack>
  );
};
