import { createTheme, loadTheme } from '@fluentui/react';

const theme = createTheme({
  palette: {
    themePrimary: '#2266E3',
  },
});

loadTheme(theme);

export const useTheme = () => theme;
