import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Pivot, PivotItem, Stack } from '@fluentui/react';

import { Heading } from '../Heading';
import { TourAccordion } from './TourAccordion';
import { useIsSmallScreen } from '../useIsSmallScreen';
import { useData, Tour } from './data';

import placeholderSrc from './assets/placeholder.png';

export const Tours = () => {
  const { t } = useTranslation();
  const data = useData();
  const isSmallScreen = useIsSmallScreen();
  const [pivot, setPivot] = useState(data[0]);
  const [selectedItem, setSelectedItem] = useState<Tour | undefined>(
    pivot.tours[0]
  );

  const changePivot = (link?: PivotItem) => {
    const pivot = data.find((pivot) => pivot.key === link?.props.itemKey)!;
    setSelectedItem(pivot.tours[0]);
    setPivot(pivot);
  };

  return (
    <Stack tokens={{ childrenGap: isSmallScreen ? 12 : 32 }}>
      <Heading
        title={t('Explore a guided tour')}
        subtitle={t(
          'Select a guided tour below to experience some of the rich Commerce features and integration solutions.'
        )}
        as={'h2'}
      />
      <Stack tokens={{ childrenGap: isSmallScreen ? 8 : 20 }}>
        <Pivot
          selectedKey={pivot.key}
          onLinkClick={changePivot}
          styles={
            isSmallScreen
              ? { link: { padding: 0 }, linkIsSelected: { padding: 0 } }
              : undefined
          }
        >
          {data.map((p) => (
            <PivotItem key={p.key} itemKey={p.key} headerText={p.name} />
          ))}
        </Pivot>
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Stack styles={{ root: { width: '100%' } }}>
            {pivot.tours.map((t) => (
              <TourAccordion
                {...t}
                isOpen={selectedItem === t}
                setIsOpen={(isOpen) => setSelectedItem(isOpen ? t : undefined)}
              />
            ))}
          </Stack>
          {!isSmallScreen && (
            <Stack styles={{ root: { width: '100%' } }}>
              {selectedItem && (
                <Image
                  src={selectedItem.picture ?? placeholderSrc}
                  alt={selectedItem.name}
                  styles={{ image: { width: '100%' } }}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
