import React, { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar as FabricSidebar } from '@business-app/fabric';

import { useTheme } from './theme';
import { useIsSmallScreen } from './LandingPage/useIsSmallScreen';

const Container: FC<{ isCollapsed: boolean }> = ({ children, isCollapsed }) => {
  const isSmallScreen = useIsSmallScreen();
  if (isSmallScreen) {
    return isCollapsed ? null : (
      <div
        style={{
          position: 'absolute',
          zIndex: 2,
          top: 0,
          left: 0,
          height: '100%',
        }}
      >
        {children}
      </div>
    );
  }
  return <>{children}</>;
};

export const Sidebar = ({
  isCollapsed,
  setIsCollapsed,
}: {
  isCollapsed: boolean;
  setIsCollapsed: (isCollapsed: boolean) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useIsSmallScreen();

  const ref = useRef<FabricSidebar>(null);

  useEffect(() => {
    if (isSmallScreen) {
      ref?.current?.setCollapsed(isCollapsed);
    }
  }, [isSmallScreen, isCollapsed]);

  const onCollapseChanged = () => {
    if (!isSmallScreen) {
      setIsCollapsed(!isCollapsed);
    }
  };

  return (
    <Container isCollapsed={isCollapsed}>
      <FabricSidebar
        ref={ref}
        collapsible={!isSmallScreen}
        defaultIsCollapsed={isCollapsed}
        onCollapseChanged={onCollapseChanged}
        redirectIfActive
        collapseButtonAriaLabel={t('Toggle sidebar')}
        items={[
          {
            key: 'home',
            name: t('Home'),
            iconProps: { iconName: 'Home' },
            href: './index.html',
            active: true,
          },
        ]}
        theme={theme}
      />
    </Container>
  );
};
