// Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license

// tslint:disable:max-line-length

import {
  IIconOptions,
  IIconSubset,
  registerIcons,
} from '@fluentui/style-utilities';

export function initializeIcons(
  baseUrl: string = '',
  options?: IIconOptions
): void {
  const subset: IIconSubset = {
    style: {
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased',
      fontStyle: 'normal',
      fontWeight: 'normal',
      speak: 'none',
    },
    fontFace: {
      fontFamily: `"FabricMDL2Icons"`,
      src: `url('${baseUrl}fabric-icons-c552d755.woff') format('woff')`,
    },
    icons: {
      AdminYLogoInverse32: '\uED73',
      DelveLogoInverse: '\uED76',
      ExchangeLogoInverse: '\uED78',
      LyncLogo: '\uED79',
      OfficeVideoLogoInverse: '\uED7A',
      SocialListeningLogo: '\uED7C',
      VisioLogoInverse: '\uED7D',
      Balloons: '\uED7E',
      Cat: '\uED7F',
      MailAlert: '\uED80',
      MailCheck: '\uED81',
      MailLowImportance: '\uED82',
      MailPause: '\uED83',
      MailRepeat: '\uED84',
      SecurityGroup: '\uED85',
      Table: '\uED86',
      AdminSLogoInverse32: '\uED72',
      VoicemailForward: '\uED87',
      AdminPLogoInverse32: '\uED71',
      AdminMLogoInverse32: '\uED6F',
      ReturnToSession: '\uED24',
      OpenFolderHorizontal: '\uED25',
      CalendarMirrored: '\uED28',
      SwayLogoInverse: '\uED29',
      OutOfOffice: '\uED34',
      SkipBack10: '\uED3C',
      SkipForward30: '\uED3D',
      Trophy: '\uED3F',
      RewardsLogo: '\uED4E',
      RewardsLogoSolid: '\uED4F',
      ReopenPages: '\uED50',
      EmojiTabSymbols: '\uED58',
      AADLogo: '\uED68',
      AccessLogo: '\uED69',
      AdminALogoInverse32: '\uED6A',
      AdminCLogoInverse32: '\uED6B',
      AdminDLogoInverse32: '\uED6C',
      AdminELogoInverse32: '\uED6D',
      AdminLLogoInverse32: '\uED6E',
      AdminOLogoInverse32: '\uED70',
      Uneditable: '\uED1D',
      VoicemailReply: '\uED88',
      RemoveEvent: '\uED8A',
      HardDrive: '\uEDA2',
      RecurringTask: '\uEDB2',
      TaskManager: '\uEDB7',
      TaskManagerMirrored: '\uEDB8',
      Combine: '\uEDBB',
      Split: '\uEDBC',
      DoubleChevronUp: '\uEDBD',
      DoubleChevronLeft: '\uEDBE',
      DoubleChevronRight: '\uEDBF',
      Ascending: '\uEDC0',
      Descending: '\uEDC1',
      TextBox: '\uEDC2',
      TextField: '\uEDC3',
      NumberField: '\uEDC4',
      Dropdown: '\uEDC5',
      PenWorkspace: '\uEDC6',
      BookingsLogo: '\uEDC7',
      ClassNotebookLogoInverse: '\uEDC8',
      DelveAnalyticsLogo: '\uEDCA',
      Subscribe: '\uEDA1',
      Waffle: '\uED89',
      Unsubscribe: '\uEDA0',
      CircleHalfFull: '\uED9E',
      EventInfo: '\uED8B',
      ForwardEvent: '\uED8C',
      WipePhone: '\uED8D',
      AddOnlineMeeting: '\uED8E',
      JoinOnlineMeeting: '\uED8F',
      RemoveLink: '\uED90',
      PeopleBlock: '\uED91',
      PeopleRepeat: '\uED92',
      PeopleAlert: '\uED93',
      PeoplePause: '\uED94',
      TransferCall: '\uED95',
      AddPhone: '\uED96',
      UnknownCall: '\uED97',
      NoteReply: '\uED98',
      NoteForward: '\uED99',
      NotePinned: '\uED9A',
      RemoveOccurrence: '\uED9B',
      Timeline: '\uED9C',
      EditNote: '\uED9D',
      Room: '\uED9F',
      Hide: '\uED1A',
      YammerLogo: '\uED19',
      SharepointLogoInverse: '\uED18',
      ExcelLogoInverse: '\uEC28',
      WordLogoInverse: '\uEC29',
      PowerPointLogoInverse: '\uEC2A',
      Cafe: '\uEC32',
      SpeedHigh: '\uEC4A',
      Commitments: '\uEC4D',
      ThisPC: '\uEC4E',
      MusicNote: '\uEC4F',
      MicOff: '\uEC54',
      PlaybackRate1x: '\uEC57',
      EdgeOldLogo: '\uEC60',
      CompletedSolid: '\uEC61',
      AlbumRemove: '\uEC62',
      MessageFill: '\uEC70',
      TabletSelected: '\uEC74',
      MobileSelected: '\uEC75',
      LaptopSelected: '\uEC76',
      TVMonitorSelected: '\uEC77',
      DeveloperTools: '\uEC7A',
      MyNetwork: '\uEC27',
      Shapes: '\uEC7C',
      HomeGroup: '\uEC26',
      Giftbox: '\uEC1F',
      JS: '\uEBF0',
      DeliveryTruck: '\uEBF4',
      ReminderPerson: '\uEBF7',
      ReminderGroup: '\uEBF8',
      ReminderTime: '\uEBF9',
      TabletMode: '\uEBFC',
      Umbrella: '\uEC04',
      NetworkTower: '\uEC05',
      CityNext: '\uEC06',
      CityNext2: '\uEC07',
      Section: '\uEC0C',
      OneNoteLogoInverse: '\uEC0D',
      ToggleFilled: '\uEC11',
      ToggleBorder: '\uEC12',
      SliderThumb: '\uEC13',
      ToggleThumb: '\uEC14',
      Documentation: '\uEC17',
      Badge: '\uEC1B',
      VisualStudioLogo: '\uEC22',
      InsertTextBox: '\uEC7D',
      LowerBrightness: '\uEC8A',
      WebComponents: '\uEC8B',
      Embed: '\uECCE',
      Product: '\uECDC',
      ProgressLoopInner: '\uECDE',
      ProgressLoopOuter: '\uECDF',
      Hot: '\uECE2',
      Blocked2: '\uECE4',
      FangBody: '\uECEB',
      Toolbox: '\uECED',
      PageHeader: '\uECEE',
      Glimmer: '\uECF4',
      ChatInviteFriend: '\uECFE',
      Brush: '\uECFF',
      Shirt: '\uED00',
      Crown: '\uED01',
      Diamond: '\uED02',
      ScaleUp: '\uED09',
      ResetDevice: '\uED10',
      QRCode: '\uED14',
      Feedback: '\uED15',
      ExploreContent: '\uECCD',
      RadioBtnOn: '\uECCB',
      RadioBtnOff: '\uECCA',
      AddTo: '\uECC8',
      OfflineStorage: '\uEC8C',
      DOM: '\uEC8D',
      CloudUpload: '\uEC8E',
      ScrollUpDown: '\uEC8F',
      DateTime: '\uEC92',
      Event: '\uECA3',
      Cake: '\uECA4',
      Tiles: '\uECA5',
      Org: '\uECA6',
      DocsLogoInverse: '\uEDCB',
      PartyLeader: '\uECA7',
      CloudAdd: '\uECA9',
      AppIconDefault: '\uECAA',
      Photo2Add: '\uECAB',
      Photo2Remove: '\uECAC',
      Calories: '\uECAD',
      POI: '\uECAF',
      RemoveContent: '\uECC7',
      DRM: '\uECA8',
      CSS: '\uEBEF',
      Dynamics365Logo: '\uEDCC',
      OfficeAssistantLogo: '\uEDCE',
      ReceiptForward: '\uEF59',
      ReceiptReply: '\uEF5A',
      ReceiptCheck: '\uEF5B',
      Fax: '\uEF5C',
      RecurringEvent: '\uEF5D',
      ReplyAlt: '\uEF5E',
      ReplyAllAlt: '\uEF5F',
      EditStyle: '\uEF60',
      EditMail: '\uEF61',
      Lifesaver: '\uEF62',
      LifesaverLock: '\uEF63',
      InboxCheck: '\uEF64',
      FolderSearch: '\uEF65',
      CollapseMenu: '\uEF66',
      ExpandMenu: '\uEF67',
      Boards: '\uEF68',
      SunAdd: '\uEF69',
      SunQuestionMark: '\uEF6A',
      LandscapeOrientation: '\uEF6B',
      PlayerSettings: '\uEF58',
      DocumentSearch: '\uEF6C',
      DocumentReply: '\uEF57',
      CaretTopRightSolid8: '\uEF55',
      ArrowUpRightMirrored8: '\uEEEF',
      ArrowDownRightMirrored8: '\uEEF0',
      RewardsLogoArt64: '\uEEF2',
      CompanyDirectory: '\uEF0D',
      OpenEnrollment: '\uEF1C',
      CompanyDirectoryMirrored: '\uEF2B',
      OneDriveAdd: '\uEF32',
      ProfileSearch: '\uEF35',
      Header2: '\uEF36',
      Header3: '\uEF37',
      Header4: '\uEF38',
      Screen: '\uEF39',
      RingerSolid: '\uEF3A',
      Eyedropper: '\uEF3C',
      MarketDown: '\uEF42',
      CalendarWorkWeek: '\uEF51',
      SidePanel: '\uEF52',
      GlobeFavorite: '\uEF53',
      CaretTopLeftSolid8: '\uEF54',
      ViewAll2: '\uEF56',
      DelveAnalytics: '\uEEEE',
      PublicCalendar: '\uEF6D',
      PublicEmail: '\uEF6F',
      RecycleBin: '\uEF87',
      EmptyRecycleBin: '\uEF88',
      Hide2: '\uEF89',
      Breadcrumb: '\uEF8C',
      BirthdayCake: '\uEF8D',
      ClearFilter: '\uEF8F',
      Flow: '\uEF90',
      FixedAssetManagement: '\uEF93',
      TimeEntry: '\uEF95',
      MailLink: '\uEFAC',
      CRMProcesses: '\uEFB1',
      PageEdit: '\uEFB6',
      PageArrowRight: '\uEFB8',
      PageRemove: '\uEFBA',
      Database: '\uEFC7',
      DataManagementSettings: '\uEFC8',
      CRMServices: '\uEFD2',
      OfficeFormsLogoInverse: '\uEF86',
      PublicContactCard: '\uEF6E',
      ATPLogo: '\uEF85',
      SkypeMessage: '\uEF83',
      PublicFolder: '\uEF70',
      WordDocument: '\uEF71',
      PowerPointDocument: '\uEF72',
      ExcelDocument: '\uEF73',
      GroupedList: '\uEF74',
      ClassroomLogo: '\uEF75',
      Sections: '\uEF76',
      EditPhoto: '\uEF77',
      Starburst: '\uEF78',
      ShareiOS: '\uEF79',
      AirTickets: '\uEF7A',
      PencilReply: '\uEF7B',
      Tiles2: '\uEF7C',
      SkypeCircleCheck: '\uEF7D',
      SkypeCircleClock: '\uEF7E',
      SkypeCircleMinus: '\uEF7F',
      SkypeCheck: '\uEF80',
      SkypeClock: '\uEF81',
      SkypeMinus: '\uEF82',
      ClosedCaption: '\uEF84',
      GoToDashboard: '\uEEED',
      DocumentSet: '\uEED6',
      ArrowDownRight8: '\uEED5',
      SingleBookmarkSolid: '\uEE00',
      DoubleChevronDown: '\uEE04',
      FollowUser: '\uEE05',
      ReplyAll: '\uEE0A',
      WorkforceManagement: '\uEE0F',
      RecruitmentManagement: '\uEE12',
      Questionnaire: '\uEE19',
      ManagerSelfService: '\uEE23',
      ProductionFloorManagement: '\uEE29',
      ProductRelease: '\uEE2E',
      ProductVariant: '\uEE30',
      ReplyMirrored: '\uEE35',
      ReplyAllMirrored: '\uEE36',
      Medal: '\uEE38',
      AddGroup: '\uEE3D',
      QuestionnaireMirrored: '\uEE4B',
      CloudImportExport: '\uEE55',
      TemporaryUser: '\uEE58',
      SingleBookmark: '\uEDFF',
      CaretSolid16: '\uEE62',
      ProgressRing5: '\uEDF6',
      ExportMirrored: '\uEDE2',
      OfficeStoreLogo: '\uEDCF',
      OneNoteEduLogoInverse: '\uEDD0',
      PlannerLogo: '\uEDD1',
      PowerApps: '\uEDD2',
      Suitcase: '\uEDD3',
      ProjectLogoInverse: '\uEDD4',
      CaretLeft8: '\uEDD5',
      CaretRight8: '\uEDD6',
      CaretUp8: '\uEDD7',
      CaretDown8: '\uEDD8',
      CaretLeftSolid8: '\uEDD9',
      CaretRightSolid8: '\uEDDA',
      CaretUpSolid8: '\uEDDB',
      CaretDownSolid8: '\uEDDC',
      ClearFormatting: '\uEDDD',
      Superscript: '\uEDDE',
      Subscript: '\uEDDF',
      Strikethrough: '\uEDE0',
      Export: '\uEDE1',
      CloudSearch: '\uEDE4',
      GroupedDescending: '\uEE66',
      GroupedAscending: '\uEE67',
      SortUp: '\uEE68',
      ContactCard: '\uEEBD',
      CustomList: '\uEEBE',
      CustomListMirrored: '\uEEBF',
      IssueTracking: '\uEEC0',
      IssueTrackingMirrored: '\uEEC1',
      PictureLibrary: '\uEEC2',
      OfficeAddinsLogo: '\uEEC7',
      OfflineOneDriveParachute: '\uEEC8',
      OfflineOneDriveParachuteDisabled: '\uEEC9',
      LargeGrid: '\uEECB',
      TriangleSolidUp12: '\uEECC',
      TriangleSolidDown12: '\uEECD',
      TriangleSolidLeft12: '\uEECE',
      TriangleSolidRight12: '\uEECF',
      TriangleUp12: '\uEED0',
      TriangleDown12: '\uEED1',
      TriangleLeft12: '\uEED2',
      TriangleRight12: '\uEED3',
      ArrowUpRight8: '\uEED4',
      ReportLibraryMirrored: '\uEEBC',
      ReportLibrary: '\uEEBB',
      FormLibraryMirrored: '\uEEBA',
      FormLibrary: '\uEEB9',
      SortDown: '\uEE69',
      AwayStatus: '\uEE6A',
      MyMoviesTV: '\uEE6C',
      SyncToPC: '\uEE6E',
      GenericScan: '\uEE6F',
      AustralianRules: '\uEE70',
      WifiEthernet: '\uEE77',
      TrackersMirrored: '\uEE92',
      DateTimeMirrored: '\uEE93',
      DynamicSMBLogo: '\uEDCD',
      StopSolid: '\uEE95',
      DoubleChevronDown12: '\uEE97',
      DoubleChevronLeft12: '\uEE98',
      DoubleChevronRight12: '\uEE99',
      CalendarAgenda: '\uEE9A',
      ConnectVirtualMachine: '\uEE9D',
      AddEvent: '\uEEB5',
      AssetLibrary: '\uEEB6',
      DataConnectionLibrary: '\uEEB7',
      DocLibrary: '\uEEB8',
      DoubleChevronUp12: '\uEE96',
      F12DevTools: '\uEBEE',
      BrowserScreenShot: '\uEBED',
      SiteScan: '\uEBEC',
      MiniExpand: '\uE93A',
      MiniContract: '\uE93B',
      Streaming: '\uE93E',
      MusicInCollection: '\uE940',
      OneDriveLogo: '\uE941',
      CompassNW: '\uE942',
      Code: '\uE943',
      LightningBolt: '\uE945',
      Info: '\uE946',
      CalculatorMultiply: '\uE947',
      CalculatorAddition: '\uE948',
      CalculatorSubtract: '\uE949',
      CalculatorPercentage: '\uE94C',
      CalculatorEqualTo: '\uE94E',
      PrintfaxPrinterFile: '\uE956',
      StorageOptical: '\uE958',
      Communications: '\uE95A',
      Headset: '\uE95B',
      FlashAuto: '\uE95C',
      FlickRight: '\uE938',
      Health: '\uE95E',
      FlickLeft: '\uE937',
      FlickDown: '\uE935',
      RadioBullet: '\uE915',
      Stopwatch: '\uE916',
      Clock: '\uE917',
      WorldClock: '\uE918',
      AlarmClock: '\uE919',
      Photo: '\uE91B',
      ActionCenter: '\uE91C',
      Hospital: '\uE91D',
      Timer: '\uE91E',
      FullCircleMask: '\uE91F',
      LocationFill: '\uE920',
      ChromeMinimize: '\uE921',
      ChromeRestore: '\uE923',
      Annotation: '\uE924',
      Fingerprint: '\uE928',
      Handwriting: '\uE929',
      ChromeFullScreen: '\uE92D',
      Completed: '\uE930',
      Label: '\uE932',
      FlickUp: '\uE936',
      Street: '\uE913',
      Webcam2: '\uE960',
      ChevronUpSmall: '\uE96D',
      FitPage: '\uE9A6',
      FitWidth: '\uE9A7',
      BidiLtr: '\uE9AA',
      BidiRtl: '\uE9AB',
      RightDoubleQuote: '\uE9B1',
      Sunny: '\uE9BD',
      CloudWeather: '\uE9BE',
      Cloudy: '\uE9BF',
      PartlyCloudyDay: '\uE9C0',
      PartlyCloudyNight: '\uE9C1',
      ClearNight: '\uE9C2',
      RainShowersDay: '\uE9C3',
      Rain: '\uE9C4',
      Thunderstorms: '\uE9C6',
      RainSnow: '\uE9C7',
      Snow: '\uE9C8',
      BlowingSnow: '\uE9C9',
      Frigid: '\uE9CA',
      Fog: '\uE9CB',
      LockSolid: '\uE9A2',
      FrontCamera: '\uE96B',
      Manufacturing: '\uE99C',
      Chart: '\uE999',
      ChevronDownSmall: '\uE96E',
      ChevronLeftSmall: '\uE96F',
      ChevronRightSmall: '\uE970',
      ChevronUpMed: '\uE971',
      ChevronDownMed: '\uE972',
      ChevronLeftMed: '\uE973',
      ChevronRightMed: '\uE974',
      Devices2: '\uE975',
      PC1: '\uE977',
      PresenceChickletVideo: '\uE979',
      Reply: '\uE97A',
      HalfAlpha: '\uE97E',
      ConstructionCone: '\uE98F',
      DoubleChevronLeftMed: '\uE991',
      Volume0: '\uE992',
      Volume1: '\uE993',
      Volume2: '\uE994',
      Volume3: '\uE995',
      Robot: '\uE99A',
      Accounts: '\uE910',
      Repair: '\uE90F',
      DockRight: '\uE90D',
      ViewAll: '\uE8A9',
      Switch: '\uE8AB',
      Rename: '\uE8AC',
      Go: '\uE8AD',
      Remote: '\uE8AF',
      SelectAll: '\uE8B3',
      Orientation: '\uE8B4',
      Import: '\uE8B5',
      Folder: '\uE8B7',
      Picture: '\uE8B9',
      ChromeClose: '\uE8BB',
      ShowResults: '\uE8BC',
      Message: '\uE8BD',
      CalendarDay: '\uE8BF',
      CalendarWeek: '\uE8C0',
      MailReplyAll: '\uE8C2',
      Read: '\uE8C3',
      Cut: '\uE8C6',
      PaymentCard: '\uE8C7',
      MailFill: '\uE8A8',
      Copy: '\uE8C8',
      OpenInNewWindow: '\uE8A7',
      Document: '\uE8A5',
      Pinned: '\uE840',
      PinnedFill: '\uE842',
      RevToggleKey: '\uE845',
      USB: '\uE88E',
      View: '\uE890',
      Previous: '\uE892',
      Next: '\uE893',
      Clear: '\uE894',
      Sync: '\uE895',
      Download: '\uE896',
      Help: '\uE897',
      Upload: '\uE898',
      Emoji: '\uE899',
      MailForward: '\uE89C',
      ClosePane: '\uE89F',
      OpenPane: '\uE8A0',
      PreviewLink: '\uE8A1',
      ZoomIn: '\uE8A3',
      Bookmarks: '\uE8A4',
      ProtectedDocument: '\uE8A6',
      Important: '\uE8C9',
      MailReply: '\uE8CA',
      Sort: '\uE8CB',
      RepeatAll: '\uE8EE',
      Calculator: '\uE8EF',
      Library: '\uE8F1',
      PostUpdate: '\uE8F3',
      NewFolder: '\uE8F4',
      CalendarReply: '\uE8F5',
      UnsyncFolder: '\uE8F6',
      SyncFolder: '\uE8F7',
      BlockContact: '\uE8F8',
      AddFriend: '\uE8FA',
      Accept: '\uE8FB',
      BulletedList: '\uE8FD',
      Preview: '\uE8FF',
      News: '\uE900',
      Chat: '\uE901',
      Group: '\uE902',
      World: '\uE909',
      Comment: '\uE90A',
      DockLeft: '\uE90C',
      RepeatOne: '\uE8ED',
      Tag: '\uE8EC',
      CellPhone: '\uE8EA',
      FontSize: '\uE8E9',
      GotoToday: '\uE8D1',
      Font: '\uE8D2',
      FontColor: '\uE8D3',
      FolderFill: '\uE8D5',
      Permissions: '\uE8D7',
      DisableUpdates: '\uE8D8',
      Unfavorite: '\uE8D9',
      Italic: '\uE8DB',
      Underline: '\uE8DC',
      Squalls: '\uE9CC',
      Bold: '\uE8DD',
      Dislike: '\uE8E0',
      Like: '\uE8E1',
      AlignRight: '\uE8E2',
      AlignCenter: '\uE8E3',
      AlignLeft: '\uE8E4',
      OpenFile: '\uE8E5',
      ClearSelection: '\uE8E6',
      FontDecrease: '\uE8E7',
      FontIncrease: '\uE8E8',
      MoveToFolder: '\uE8DE',
      Duststorm: '\uE9CD',
      Unknown: '\uE9CE',
      Precipitation: '\uE9CF',
      Money: '\uEAFD',
      PieDouble: '\uEB04',
      PieSingle: '\uEB05',
      RemoveFilter: '\uEB08',
      Savings: '\uEB0B',
      Sell: '\uEB0C',
      StockDown: '\uEB0F',
      StockUp: '\uEB11',
      MSNVolume: '\uEB15',
      Lamp: '\uEB19',
      Source: '\uEB1B',
      MSNVideos: '\uEB1C',
      Cricket: '\uEB1E',
      Golf: '\uEB1F',
      Baseball: '\uEB20',
      Soccer: '\uEB21',
      MoreSports: '\uEB22',
      AutoRacing: '\uEB24',
      CollegeHoops: '\uEB25',
      Market: '\uEAFC',
      CollegeFootball: '\uEB26',
      Currency: '\uEAF5',
      Coffee: '\uEAEF',
      Broom: '\uEA99',
      AddToShoppingList: '\uEA9A',
      Cocktails: '\uEA9D',
      Wines: '\uEABF',
      Articles: '\uEAC1',
      Cycling: '\uEAC7',
      DietPlanNotebook: '\uEAC8',
      Pill: '\uEACB',
      ExerciseTracker: '\uEACC',
      HandsFree: '\uEAD0',
      Medical: '\uEAD4',
      Running: '\uEADA',
      Weights: '\uEADB',
      Trackers: '\uEADF',
      Vaccination: '\uEAE0',
      AddNotes: '\uEAE3',
      AllCurrency: '\uEAE4',
      BarChart4: '\uEAE7',
      CirclePlus: '\uEAEE',
      Cotton: '\uEAF3',
      ProFootball: '\uEB27',
      ProHockey: '\uEB28',
      Rugby: '\uEB2D',
      OutlookLogoInverse: '\uEB6D',
      OfficeLogo: '\uEB6E',
      SkypeLogo: '\uEB6F',
      Door: '\uEB75',
      EditMirrored: '\uEB7E',
      GiftCard: '\uEB8E',
      DoubleBookmark: '\uEB8F',
      StatusErrorFull: '\uEB90',
      Certificate: '\uEB95',
      FastForward: '\uEB9D',
      Rewind: '\uEB9E',
      Photo2: '\uEB9F',
      OpenSource: '\uEBC2',
      Movers: '\uEBCD',
      CloudDownload: '\uEBD3',
      Family: '\uEBDA',
      WindDirection: '\uEBE6',
      Bug: '\uEBE8',
      MSNLogo: '\uEB6C',
      BingLogo: '\uEB6B',
      AzureLogo: '\uEB6A',
      NUIFace: '\uEB68',
      SubstitutionsIn: '\uEB31',
      Tennis: '\uEB33',
      Arrivals: '\uEB34',
      Design: '\uEB3C',
      Website: '\uEB41',
      Drop: '\uEB42',
      HistoricalWeather: '\uEB43',
      SkiResorts: '\uEB45',
      Snowflake: '\uEB46',
      MultiSelectMirrored: '\uEA98',
      BusSolid: '\uEB47',
      EndPointSolid: '\uEB4B',
      AirplaneSolid: '\uEB4C',
      TrainSolid: '\uEB4D',
      Heart: '\uEB51',
      HeartFill: '\uEB52',
      Ticket: '\uEB54',
      WifiWarning4: '\uEB63',
      Devices4: '\uEB66',
      FerrySolid: '\uEB48',
      EditContact: '\uEFD3',
      StoreLogo16: '\uEA96',
      PDF: '\uEA90',
      HourGlass: '\uEA03',
      StoreLogoMed20: '\uEA04',
      TimeSheet: '\uEA05',
      TriangleSolid: '\uEA08',
      UpgradeAnalysis: '\uEA0B',
      VideoSolid: '\uEA0C',
      RainShowersNight: '\uEA0F',
      SnowShowerNight: '\uEA11',
      Teamwork: '\uEA12',
      HailNight: '\uEA13',
      PeopleAdd: '\uEA15',
      Glasses: '\uEA16',
      DateTime2: '\uEA17',
      Shield: '\uEA18',
      Header1: '\uEA19',
      PageAdd: '\uEA1A',
      NumberedList: '\uEA1C',
      PowerBILogo: '\uEA1E',
      Info2: '\uEA1F',
      LightSnow: '\uEA02',
      MusicInCollectionFill: '\uEA36',
      WorkFlow: '\uEA01',
      SnowShowerDay: '\uE9FD',
      SortLines: '\uE9D0',
      Ribbon: '\uE9D1',
      AreaChart: '\uE9D2',
      Assign: '\uE9D3',
      FlowChart: '\uE9D4',
      CheckList: '\uE9D5',
      Diagnostic: '\uE9D9',
      Generate: '\uE9DA',
      Total: '\uE9DF',
      LineChart: '\uE9E6',
      Equalizer: '\uE9E9',
      BarChartHorizontal: '\uE9EB',
      BarChartVertical: '\uE9EC',
      Freezing: '\uE9EF',
      FunnelChart: '\uE9F1',
      Processing: '\uE9F5',
      Quantity: '\uE9F8',
      ReportDocument: '\uE9F9',
      StackColumnChart: '\uE9FC',
      HailDay: '\uEA00',
      List: '\uEA37',
      Asterisk: '\uEA38',
      ErrorBadge: '\uEA39',
      OpenWithMirrored: '\uEA5C',
      ParkingLocationMirrored: '\uEA5E',
      SendMirrored: '\uEA63',
      ShowResultsMirrored: '\uEA65',
      ThumbnailViewMirrored: '\uEA67',
      Media: '\uEA69',
      SyncError: '\uEA6A',
      Devices3: '\uEA6C',
      FlashOff: '\uEA6E',
      Focus: '\uEA6F',
      VideoLightOff: '\uEA74',
      Lightbulb: '\uEA80',
      StatusTriangle: '\uEA82',
      VolumeDisabled: '\uEA85',
      Puzzle: '\uEA86',
      EmojiNeutral: '\uEA87',
      EmojiDisappointed: '\uEA88',
      HomeSolid: '\uEA8A',
      Ringer: '\uEA8F',
      OpenPaneMirrored: '\uEA5B',
      MiniExpandMirrored: '\uEA5A',
      MiniContractMirrored: '\uEA59',
      MailReplyAllMirrored: '\uEA58',
      CircleRing: '\uEA3A',
      CircleFill: '\uEA3B',
      Record2: '\uEA3F',
      AllAppsMirrored: '\uEA40',
      BookmarksMirrored: '\uEA41',
      BulletedListMirrored: '\uEA42',
      CaretHollowMirrored: '\uEA45',
      CaretSolidMirrored: '\uEA46',
      ChromeBackMirrored: '\uEA47',
      HeartBroken: '\uEA92',
      ClearSelectionMirrored: '\uEA48',
      DockLeftMirrored: '\uEA4C',
      DoubleChevronLeftMedMirrored: '\uEA4D',
      GoMirrored: '\uEA4F',
      HelpMirrored: '\uEA51',
      ImportMirrored: '\uEA52',
      ImportAllMirrored: '\uEA53',
      ListMirrored: '\uEA55',
      MailForwardMirrored: '\uEA56',
      MailReplyMirrored: '\uEA57',
      ClosePaneMirrored: '\uEA49',
      DefenderApp: '\uE83D',
      ConnectContacts: '\uEFD4',
      AppIconDefaultList: '\uEFDE',
      EventDeclined: '\uF425',
      CustomerAssets: '\uF426',
      IDBadge: '\uF427',
      BackgroundColor: '\uF42B',
      OfficeFormsLogoInverse16: '\uF433',
      OfficeFormsLogo: '\uF434',
      OfficeFormsLogo16: '\uF436',
      OfficeFormsLogoInverse24: '\uF43A',
      OfficeFormsLogo24: '\uF43B',
      PageLock: '\uF43F',
      NotExecuted: '\uF440',
      NotImpactedSolid: '\uF441',
      FieldReadOnly: '\uF442',
      FieldRequired: '\uF443',
      BacklogBoard: '\uF444',
      ExternalBuild: '\uF445',
      ExternalTFVC: '\uF446',
      ExternalXAML: '\uF447',
      IssueSolid: '\uF448',
      EventTentativeMirrored: '\uF424',
      DefectSolid: '\uF449',
      EventTentative: '\uF423',
      VoicemailIRM: '\uF421',
      RectangularClipping: '\uF407',
      TeamsLogo16: '\uF40A',
      Spacer: '\uF40D',
      SkypeLogo16: '\uF40E',
      SkypeForBusinessLogo16: '\uF40F',
      SkypeForBusinessLogoFill16: '\uF410',
      FilterSolid: '\uF412',
      MailUndelivered: '\uF415',
      MailTentative: '\uF416',
      MailTentativeMirrored: '\uF417',
      MailReminder: '\uF418',
      ReceiptUndelivered: '\uF419',
      ReceiptTentative: '\uF41A',
      ReceiptTentativeMirrored: '\uF41B',
      Inbox: '\uF41C',
      IRMReply: '\uF41D',
      IRMReplyMirrored: '\uF41E',
      IRMForward: '\uF41F',
      IRMForwardMirrored: '\uF420',
      EventAccepted: '\uF422',
      DeclineCall: '\uF405',
      LadybugSolid: '\uF44A',
      NugetLogo: '\uF44C',
      GallatinLogo: '\uF496',
      Globe2: '\uF49A',
      Guitar: '\uF49B',
      Breakfast: '\uF49C',
      Brunch: '\uF49D',
      BeerMug: '\uF49E',
      Vacation: '\uF49F',
      Teeth: '\uF4A0',
      Taxi: '\uF4A1',
      Chopsticks: '\uF4A2',
      SyncOccurence: '\uF4A3',
      UnsyncOccurence: '\uF4A4',
      GIF: '\uF4A9',
      PrimaryCalendar: '\uF4AE',
      SearchCalendar: '\uF4AF',
      VideoOff: '\uF4B0',
      MicrosoftFlowLogo: '\uF4B1',
      BusinessCenterLogo: '\uF4B2',
      ToDoLogoBottom: '\uF4B3',
      ProtectionCenterLogo32: '\uF494',
      KaizalaLogo: '\uF492',
      TFVCLogo: '\uF44D',
      ProjectLogo32: '\uF47E',
      ProjectLogo16: '\uF480',
      SwayLogo32: '\uF482',
      SwayLogoFill32: '\uF483',
      SwayLogo16: '\uF484',
      SwayLogoFill16: '\uF485',
      ClassNotebookLogo32: '\uF486',
      ClassNotebookLogoFill32: '\uF487',
      ClassNotebookLogo16: '\uF488',
      ClassNotebookLogoFill16: '\uF489',
      ClassNotebookLogoInverse32: '\uF48A',
      ClassNotebookLogoInverse16: '\uF48B',
      StaffNotebookLogo32: '\uF48C',
      StaffNotebookLogoFill32: '\uF48D',
      StaffNotebookLogo16: '\uF48E',
      StaffNotebookLogoFill16: '\uF48F',
      StaffNotebookLogoInverted32: '\uF490',
      StaffNotebookLogoInverted16: '\uF491',
      TaskLogo: '\uF493',
      HideVisualFilter: '\uF403',
      ContactLock: '\uF400',
      AutoDeploySettings: '\uF3FA',
      CaretBottomLeftCenter8: '\uF365',
      CaretTopRightCenter8: '\uF366',
      CaretTopLeftCenter8: '\uF367',
      DonutChart: '\uF368',
      ChevronUnfold10: '\uF369',
      ChevronFold10: '\uF36A',
      DoubleChevronDown8: '\uF36B',
      DoubleChevronUp8: '\uF36C',
      DoubleChevronLeft8: '\uF36D',
      DoubleChevronRight8: '\uF36E',
      ChevronDownEnd6: '\uF36F',
      ChevronUpEnd6: '\uF370',
      ChevronLeftEnd6: '\uF371',
      ChevronRightEnd6: '\uF372',
      ContextMenu: '\uF37C',
      CaretBottomRightCenter8: '\uF364',
      AzureAPIManagement: '\uF37F',
      ProjectCollection: '\uF363',
      TimelineMatrixView: '\uF361',
      OfflineStorageSolid: '\uF34E',
      BankSolid: '\uF34F',
      DecisionSolid: '\uF350',
      Parachute: '\uF351',
      ParachuteSolid: '\uF352',
      FiltersSolid: '\uF353',
      ColorSolid: '\uF354',
      ReviewSolid: '\uF355',
      ReviewRequestSolid: '\uF356',
      ReviewRequestMirroredSolid: '\uF357',
      ReviewResponseSolid: '\uF358',
      FeedbackRequestSolid: '\uF359',
      FeedbackRequestMirroredSolid: '\uF35A',
      FeedbackResponseSolid: '\uF35B',
      WorkItemBar: '\uF35C',
      WorkItemBarSolid: '\uF35D',
      Separator: '\uF35E',
      NavigateExternalInline: '\uF35F',
      PlanView: '\uF360',
      EngineeringGroup: '\uF362',
      AzureServiceEndpoint: '\uF380',
      VSTSLogo: '\uF381',
      VSTSAltLogo1: '\uF382',
      TestStep: '\uF3AC',
      TestParameter: '\uF3AD',
      TestSuite: '\uF3AE',
      TestCase: '\uF3AF',
      Sprint: '\uF3B0',
      SignOut: '\uF3B1',
      TriggerApproval: '\uF3B2',
      Rocket: '\uF3B3',
      AzureKeyVault: '\uF3B4',
      Onboarding: '\uF3BA',
      Transition: '\uF3BC',
      LikeSolid: '\uF3BF',
      DislikeSolid: '\uF3C0',
      D365CustomerInsights: '\uF3C8',
      EditCreate: '\uF3C9',
      PlayReverseResume: '\uF3E4',
      PlayReverse: '\uF3E5',
      SearchData: '\uF3F1',
      UnSetColor: '\uF3F9',
      TestPlan: '\uF3AB',
      TestImpactSolid: '\uF3AA',
      TestUserSolid: '\uF3A9',
      TestAutoSolid: '\uF3A8',
      VSTSAltLogo2: '\uF383',
      FileTypeSolution: '\uF387',
      DateTime12: '\uF38F',
      WordLogoInverse16: '\uF390',
      WordLogo16: '\uF391',
      PowerPointLogoInverse16: '\uF393',
      PowerPointLogo16: '\uF394',
      ExcelLogoInverse16: '\uF396',
      ExcelLogo16: '\uF397',
      ToDoLogoTop: '\uF4B4',
      OneNoteLogoInverse16: '\uF399',
      OutlookLogoInverse16: '\uF39C',
      OutlookLogo16: '\uF39D',
      PublisherLogoInverse16: '\uF39F',
      PublisherLogo16: '\uF3A0',
      VisioLogoInverse16: '\uF3A2',
      VisioLogo16: '\uF3A3',
      TestBeaker: '\uF3A5',
      TestBeakerSolid: '\uF3A6',
      TestExploreSolid: '\uF3A7',
      OneNoteLogo16: '\uF39A',
      AsteriskSolid: '\uF34D',
      EditSolid12: '\uF4B5',
      UneditableSolid12: '\uF4B7',
      AccountManagement: '\uF55C',
      DiagnosticDataViewerApp: '\uF568',
      ReportWarning: '\uF569',
      PlaySolid: '\uF5B0',
      TransitionPop: '\uF5B2',
      TransitionPush: '\uF5B3',
      TransitionEffect: '\uF5B4',
      LookupEntities: '\uF5B5',
      ExploreData: '\uF5B6',
      AddBookmark: '\uF5B7',
      SearchBookmark: '\uF5B8',
      DrillThrough: '\uF5B9',
      MasterDatabase: '\uF5BA',
      CertifiedDatabase: '\uF5BB',
      MaximumValue: '\uF5BC',
      MinimumValue: '\uF5BD',
      CriticalErrorSolid: '\uF5C9',
      VisualStudioIDELogo32: '\uF5D0',
      HardDriveUnlock: '\uF55B',
      HardDriveLock: '\uF55A',
      CalendarSettings: '\uF558',
      FunctionalManagerDashboard: '\uF542',
      BIDashboard: '\uF543',
      CodeEdit: '\uF544',
      RenewalCurrent: '\uF545',
      RenewalFuture: '\uF546',
      SplitObject: '\uF547',
      BulkUpload: '\uF548',
      DownloadDocument: '\uF549',
      GreetingCard: '\uF54B',
      Flower: '\uF54E',
      WaitlistConfirm: '\uF550',
      WaitlistConfirmMirrored: '\uF551',
      LaptopSecure: '\uF552',
      DragObject: '\uF553',
      EntryView: '\uF554',
      EntryDecline: '\uF555',
      ContactCardSettings: '\uF556',
      ContactCardSettingsMirrored: '\uF557',
      CalendarSettingsMirrored: '\uF559',
      OneDriveFolder16: '\uF53B',
      PasteAsText: '\uF5D5',
      ContentSettings: '\uF647',
      ZoomToFit: '\uF649',
      InsertColumnsLeft: '\uF64A',
      InsertColumnsRight: '\uF64B',
      InsertRowsAbove: '\uF64C',
      InsertRowsBelow: '\uF64D',
      DeleteColumns: '\uF64E',
      DeleteRows: '\uF64F',
      DeleteRowsMirrored: '\uF650',
      DeleteTable: '\uF651',
      AccountBrowser: '\uF652',
      VersionControlPush: '\uF664',
      RectangleShapeSolid: '\uF640',
      DropShapeSolid: '\uF63F',
      SquareShapeSolid: '\uF63D',
      PasteAsCode: '\uF5D6',
      BrowserTab: '\uF5D7',
      BrowserTabScreenshot: '\uF5D8',
      DesktopScreenshot: '\uF5D9',
      FileYML: '\uF5DA',
      ClipboardSolid: '\uF5DC',
      FabricUserFolder: '\uF5E5',
      FabricNetworkFolder: '\uF5E6',
      BullseyeTarget: '\uF5F0',
      AnalyticsView: '\uF5F1',
      Video360Generic: '\uF609',
      Untag: '\uF60B',
      Leave: '\uF627',
      Trending12: '\uF62D',
      Blocked12: '\uF62E',
      Warning12: '\uF62F',
      CheckedOutByOther12: '\uF630',
      CheckedOutByYou12: '\uF631',
      CircleShapeSolid: '\uF63C',
      TriangleShapeSolid: '\uF63E',
      SpecialEvent: '\uF536',
      DrillShow: '\uF535',
      DrillExpand: '\uF534',
      UngroupObject: '\uF4F2',
      AlignHorizontalLeft: '\uF4F3',
      AlignHorizontalCenter: '\uF4F4',
      AlignHorizontalRight: '\uF4F5',
      AlignVerticalTop: '\uF4F6',
      AlignVerticalCenter: '\uF4F7',
      AlignVerticalBottom: '\uF4F8',
      HorizontalDistributeCenter: '\uF4F9',
      VerticalDistributeCenter: '\uF4FA',
      Ellipse: '\uF4FB',
      Line: '\uF4FC',
      Octagon: '\uF4FD',
      Hexagon: '\uF4FE',
      Pentagon: '\uF4FF',
      RightTriangle: '\uF500',
      HalfCircle: '\uF501',
      QuarterCircle: '\uF502',
      ThreeQuarterCircle: '\uF503',
      '6PointStar': '\uF504',
      GroupObject: '\uF4F1',
      '12PointStar': '\uF505',
      SemiboldWeight: '\uF4F0',
      LightWeight: '\uF4EE',
      UneditableSolidMirrored12: '\uF4B8',
      UneditableMirrored: '\uF4B9',
      AdminALogo32: '\uF4BA',
      AdminALogoFill32: '\uF4BB',
      ToDoLogoInverse: '\uF4BC',
      Snooze: '\uF4BD',
      CortanaLogoBeckonInner: '\uF4C6',
      CortanaLogoBeckonOuter: '\uF4C7',
      CortanaLogoReadyInner: '\uF4C8',
      CortanaLogoReadyOuter: '\uF4C9',
      ShowVisualFilter: '\uF4DE',
      WaffleOffice365: '\uF4E0',
      AddConnection: '\uF4E1',
      ImageSearch: '\uF4E8',
      NewsSearch: '\uF4E9',
      VideoSearch: '\uF4EA',
      R: '\uF4EB',
      FontColorA: '\uF4EC',
      FontColorSwatch: '\uF4ED',
      NormalWeight: '\uF4EF',
      ArrangeBringToFront: '\uF506',
      ArrangeSendToBack: '\uF507',
      ArrangeSendBackward: '\uF508',
      VisualsStore: '\uF521',
      PictureCenter: '\uF522',
      PictureFill: '\uF523',
      PicturePosition: '\uF524',
      PictureStretch: '\uF525',
      PictureTile: '\uF526',
      Slider: '\uF527',
      SliderHandleSize: '\uF528',
      DefaultRatio: '\uF529',
      NumberSequence: '\uF52A',
      GUID: '\uF52B',
      ReportAdd: '\uF52C',
      DashboardAdd: '\uF52D',
      MapPinSolid: '\uF52E',
      WebPublish: '\uF52F',
      PieSingleSolid: '\uF530',
      BlockedSolid: '\uF531',
      DrillDown: '\uF532',
      DrillDownSolid: '\uF533',
      VisualsFolder: '\uF520',
      TextOverflow: '\uF51F',
      AlignJustify: '\uF51E',
      NavigationFlipper: '\uF51D',
      ArrangeBringForward: '\uF509',
      BorderDash: '\uF50A',
      BorderDot: '\uF50B',
      LineStyle: '\uF50C',
      LineThickness: '\uF50D',
      WindowEdit: '\uF50E',
      HintText: '\uF50F',
      MediaAdd: '\uF510',
      AnchorLock: '\uF511',
      EditSolidMirrored12: '\uF4B6',
      AutoHeight: '\uF512',
      ChartXAngle: '\uF514',
      ChartYAngle: '\uF515',
      Combobox: '\uF516',
      LineSpacing: '\uF517',
      Padding: '\uF518',
      PaddingTop: '\uF519',
      PaddingBottom: '\uF51A',
      PaddingLeft: '\uF51B',
      PaddingRight: '\uF51C',
      ChartSeries: '\uF513',
      DiamondSolid: '\uF34C',
      ParkingMirroredSolid: '\uF34B',
      ParkingSolid: '\uF34A',
      CubeShape: '\uF1AA',
      Trophy2: '\uF1AE',
      BucketColor: '\uF1B6',
      BucketColorFill: '\uF1B7',
      Taskboard: '\uF1C2',
      SingleColumn: '\uF1D3',
      DoubleColumn: '\uF1D4',
      TripleColumn: '\uF1D5',
      ColumnLeftTwoThirds: '\uF1D6',
      ColumnRightTwoThirds: '\uF1D7',
      AnalyticsLogo: '\uF1DE',
      AnalyticsQuery: '\uF1DF',
      NewAnalyticsQuery: '\uF1E0',
      AnalyticsReport: '\uF1E1',
      WordLogo: '\uF1E3',
      ExcelLogo: '\uF1E5',
      OneNoteLogo: '\uF1E7',
      OutlookLogo: '\uF1E9',
      PowerPointLogo: '\uF1EB',
      RectangleShape: '\uF1A9',
      PublisherLogo: '\uF1ED',
      DropShape: '\uF1A8',
      SquareShape: '\uF1A6',
      GroupList: '\uF168',
      ProgressRingDots: '\uF16A',
      CheckboxIndeterminateCombo: '\uF16E',
      CaloriesAdd: '\uF172',
      BranchFork: '\uF173',
      MuteChat: '\uF17A',
      AddHome: '\uF17B',
      AddWork: '\uF17C',
      ScreenTime: '\uF182',
      MobileReport: '\uF18A',
      ScaleVolume: '\uF18C',
      HardDriveGroup: '\uF18F',
      FastMode: '\uF19A',
      ToggleLeft: '\uF19E',
      ToggleRight: '\uF19F',
      FullView: '\uF1A2',
      FocusView: '\uF1A3',
      CircleShape: '\uF1A5',
      TriangleShape: '\uF1A7',
      InfoSolid: '\uF167',
      ScheduleEventAction: '\uF1EF',
      LocalAdmin: '\uF1FB',
      StepSharedInsert: '\uF245',
      ViewDashboard: '\uF246',
      ViewList: '\uF247',
      ViewListGroup: '\uF248',
      ViewListTree: '\uF249',
      TriggerAuto: '\uF24A',
      TriggerUser: '\uF24B',
      PivotChart: '\uF24C',
      StackedBarChart: '\uF24D',
      StackedLineChart: '\uF24E',
      BuildQueue: '\uF24F',
      BuildQueueNew: '\uF250',
      UserFollowed: '\uF25C',
      ContactLink: '\uF25F',
      Clicked: '\uF268',
      Stack: '\uF26F',
      Bullseye: '\uF272',
      VennDiagram: '\uF273',
      FiveTileGrid: '\uF274',
      StepSharedAdd: '\uF244',
      FlameSolid: '\uF1F3',
      StepShared: '\uF243',
      Step: '\uF241',
      PowerShell: '\uF1FD',
      ServerProcesses: '\uF1FE',
      RegistryEditor: '\uF1FF',
      Server: '\uF201',
      SaveAll: '\uF203',
      CostContralLedgerAdmin: '\uF208',
      LinkedInLogo: '\uF20A',
      Decimals: '\uF218',
      FilterAscending: '\uF21A',
      FilterDescending: '\uF21B',
      SidePanelMirrored: '\uF221',
      ProtectRestrict: '\uF22A',
      Blog: '\uF22B',
      IOT: '\uF22C',
      UnknownMirrored: '\uF22E',
      PublicContactCardMirrored: '\uF230',
      GridViewSmall: '\uF232',
      GridViewMedium: '\uF233',
      GridViewLarge: '\uF234',
      StepInsert: '\uF242',
      CollapseContentSingle: '\uF166',
      CollapseContent: '\uF165',
      ExploreContentSingle: '\uF164',
      ArrowUpRight: '\uF069',
      CaretRight: '\uF06B',
      SetAction: '\uF071',
      ChatBot: '\uF08B',
      CaretSolidLeft: '\uF08D',
      CaretSolidDown: '\uF08E',
      CaretSolidRight: '\uF08F',
      CaretSolidUp: '\uF090',
      PowerAppsLogo: '\uF091',
      PowerApps2Logo: '\uF092',
      SearchIssue: '\uF09A',
      SearchIssueMirrored: '\uF09B',
      FabricAssetLibrary: '\uF09C',
      FabricDataConnectionLibrary: '\uF09D',
      FabricDocLibrary: '\uF09E',
      FabricFormLibrary: '\uF09F',
      FabricFormLibraryMirrored: '\uF0A0',
      FabricReportLibrary: '\uF0A1',
      FabricReportLibraryMirrored: '\uF0A2',
      EventDate: '\uF059',
      FabricPublicFolder: '\uF0A3',
      Compare: '\uF057',
      MessageFriendRequest: '\uF055',
      ActivateOrders: '\uEFE0',
      DeactivateOrders: '\uEFE1',
      ProductCatalog: '\uEFE8',
      ScatterChart: '\uEFEB',
      AccountActivity: '\uEFF4',
      DocumentManagement: '\uEFFC',
      CRMReport: '\uEFFE',
      KnowledgeArticle: '\uF000',
      Relationship: '\uF003',
      HomeVerify: '\uF00E',
      ZipFolder: '\uF012',
      SurveyQuestions: '\uF01B',
      TextDocument: '\uF029',
      TextDocumentShared: '\uF02B',
      PageCheckedOut: '\uF02C',
      PageShared: '\uF02D',
      SaveAndClose: '\uF038',
      Script: '\uF03A',
      Archive: '\uF03F',
      ActivityFeed: '\uF056',
      FabricFolderSearch: '\uF0A4',
      FabricMovetoFolder: '\uF0A5',
      FabricUnsyncFolder: '\uF0A6',
      CaretBottomRightSolid8: '\uF122',
      FolderHorizontal: '\uF12B',
      MicrosoftStaffhubLogo: '\uF130',
      GiftboxOpen: '\uF133',
      StatusCircleOuter: '\uF136',
      StatusCircleInner: '\uF137',
      StatusCircleRing: '\uF138',
      StatusTriangleOuter: '\uF139',
      StatusTriangleInner: '\uF13A',
      StatusTriangleExclamation: '\uF13B',
      StatusCircleExclamation: '\uF13C',
      StatusCircleErrorX: '\uF13D',
      StatusCircleCheckmark: '\uF13E',
      StatusCircleInfo: '\uF13F',
      StatusCircleBlock: '\uF140',
      StatusCircleBlock2: '\uF141',
      StatusCircleQuestionMark: '\uF142',
      StatusCircleSync: '\uF143',
      Toll: '\uF160',
      CaretBottomLeftSolid8: '\uF121',
      ScreenPreviewOn: '\uF11E',
      ReadOutLoud: '\uF112',
      PageList: '\uF106',
      FabricSyncFolder: '\uF0A7',
      FabricOpenFolderHorizontal: '\uF0A8',
      FabricFolder: '\uF0A9',
      FabricFolderFill: '\uF0AA',
      FabricNewFolder: '\uF0AB',
      FabricPictureLibrary: '\uF0AC',
      PhotoVideoMedia: '\uF0B1',
      AddFavorite: '\uF0C8',
      AddFavoriteFill: '\uF0C9',
      FocalPoint: '\uF277',
      BufferTimeBefore: '\uF0CF',
      BufferTimeBoth: '\uF0D1',
      PublishContent: '\uF0D4',
      ClipboardList: '\uF0E3',
      ClipboardListMirrored: '\uF0E4',
      CannedChat: '\uF0F2',
      DefenderBadge12: '\uF0FB',
      SkypeForBusinessLogo: '\uF0FC',
      TabCenter: '\uF100',
      PageCheckedin: '\uF104',
      BufferTimeAfter: '\uF0D0',
      Insert: '\uF278',
      RingerRemove: '\uF279',
      TeamsLogoInverse: '\uF27A',
      CoffeeScript: '\uF2FA',
      MarkDownLanguage: '\uF2FB',
      FullWidth: '\uF2FE',
      FullWidthEdit: '\uF2FF',
      Plug: '\uF300',
      PlugSolid: '\uF301',
      PlugConnected: '\uF302',
      PlugDisconnected: '\uF303',
      UnlockSolid: '\uF304',
      Variable: '\uF305',
      Parameter: '\uF306',
      CommentUrgent: '\uF307',
      Storyboard: '\uF308',
      DiffInline: '\uF309',
      DiffSideBySide: '\uF30A',
      ImageDiff: '\uF30B',
      ImagePixel: '\uF30C',
      FileBug: '\uF30D',
      FileCode: '\uF30E',
      PY: '\uF2F9',
      FileComment: '\uF30F',
      PythonLanguage: '\uF2F8',
      FSharp: '\uF2F6',
      CircleAddition: '\uF2E3',
      CircleAdditionSolid: '\uF2E4',
      FilePDB: '\uF2E5',
      FileTemplate: '\uF2E6',
      FileSQL: '\uF2E7',
      FileJAVA: '\uF2E8',
      FileASPX: '\uF2E9',
      FileCSS: '\uF2EA',
      FileSass: '\uF2EB',
      FileLess: '\uF2EC',
      FileHTML: '\uF2ED',
      JavaScriptLanguage: '\uF2EE',
      CSharpLanguage: '\uF2EF',
      CSharp: '\uF2F0',
      VisualBasicLanguage: '\uF2F1',
      VB: '\uF2F2',
      CPlusPlusLanguage: '\uF2F3',
      CPlusPlus: '\uF2F4',
      FSharpLanguage: '\uF2F5',
      TypeScriptLanguage: '\uF2F7',
      BusinessHoursSign: '\uF310',
      FileImage: '\uF311',
      FileSymlink: '\uF312',
      Trophy2Solid: '\uF337',
      QuickNoteSolid: '\uF338',
      ConstructionConeSolid: '\uF339',
      PageListSolid: '\uF33A',
      PageListMirroredSolid: '\uF33B',
      StarburstSolid: '\uF33C',
      ReadingModeSolid: '\uF33D',
      SadSolid: '\uF33E',
      HealthSolid: '\uF33F',
      ShieldSolid: '\uF340',
      GiftBoxSolid: '\uF341',
      ShoppingCartSolid: '\uF342',
      MailSolid: '\uF343',
      ChatSolid: '\uF344',
      RibbonSolid: '\uF345',
      FinancialSolid: '\uF346',
      FinancialMirroredSolid: '\uF347',
      HeadsetSolid: '\uF348',
      PermissionsSolid: '\uF349',
      CrownSolid: '\uF336',
      BugSolid: '\uF335',
      ConfigurationSolid: '\uF334',
      TaskSolid: '\uF333',
      AutoFillTemplate: '\uF313',
      WorkItem: '\uF314',
      WorkItemBug: '\uF315',
      LogRemove: '\uF316',
      ColumnOptions: '\uF317',
      Packages: '\uF318',
      BuildIssue: '\uF319',
      AssessmentGroup: '\uF31A',
      VariableGroup: '\uF31B',
      UnknownMirroredSolid: '\uF2E2',
      FullHistory: '\uF31C',
      SingleColumnEdit: '\uF321',
      DoubleColumnEdit: '\uF322',
      TripleColumnEdit: '\uF323',
      ColumnLeftTwoThirdsEdit: '\uF324',
      ColumnRightTwoThirdsEdit: '\uF325',
      StreamLogo: '\uF329',
      PassiveAuthentication: '\uF32A',
      AlertSolid: '\uF331',
      MegaphoneSolid: '\uF332',
      Wheelchair: '\uF31F',
      AppIconDefaultAdd: '\uEFDA',
      UnknownSolid: '\uF2E1',
      NavigateForward: '\uF2DF',
      RawSource: '\uF299',
      MergeDuplicate: '\uF29A',
      RowsGroup: '\uF29B',
      RowsChild: '\uF29C',
      Deploy: '\uF29D',
      Redeploy: '\uF29E',
      ServerEnviroment: '\uF29F',
      VisioDiagram: '\uF2A0',
      HighlightMappedShapes: '\uF2A1',
      TextCallout: '\uF2A2',
      IconSetsFlag: '\uF2A4',
      VisioLogo: '\uF2A7',
      VisioDocument: '\uF2A9',
      TimelineProgress: '\uF2AA',
      TimelineDelivery: '\uF2AB',
      Backlog: '\uF2AC',
      TeamFavorite: '\uF2AD',
      TaskGroup: '\uF2AE',
      TaskGroupMirrored: '\uF2AF',
      BranchShelveset: '\uF298',
      ScopeTemplate: '\uF2B0',
      BranchSearch: '\uF297',
      BranchMerge: '\uF295',
      TeamsLogo: '\uF27B',
      SkypeForBusinessLogoFill: '\uF27D',
      SharepointLogo: '\uF27E',
      DelveLogo: '\uF280',
      DelveLogoFill: '\uF281',
      OfficeVideoLogo: '\uF282',
      OfficeVideoLogoFill: '\uF283',
      ExchangeLogo: '\uF284',
      Signin: '\uF286',
      DocumentApproval: '\uF28B',
      CloneToDesktop: '\uF28C',
      InstallToDrive: '\uF28D',
      Blur: '\uF28E',
      Build: '\uF28F',
      ProcessMetaTask: '\uF290',
      BranchFork2: '\uF291',
      BranchLocked: '\uF292',
      BranchCommit: '\uF293',
      BranchCompare: '\uF294',
      BranchPullRequest: '\uF296',
      AssessmentGroupTemplate: '\uF2B1',
      NewTeamProject: '\uF2B2',
      CommentAdd: '\uF2B3',
      RepoSolid: '\uF2CC',
      FolderQuery: '\uF2CD',
      FolderList: '\uF2CE',
      FolderListMirrored: '\uF2CF',
      LocationOutline: '\uF2D0',
      POISolid: '\uF2D1',
      CalculatorNotEqualTo: '\uF2D2',
      BoxSubtractSolid: '\uF2D3',
      BoxAdditionSolid: '\uF2D4',
      BoxMultiplySolid: '\uF2D5',
      BoxPlaySolid: '\uF2D6',
      BoxCheckmarkSolid: '\uF2D7',
      CirclePauseSolid: '\uF2D8',
      CirclePause: '\uF2D9',
      MSNVideosSolid: '\uF2DA',
      CircleStopSolid: '\uF2DB',
      CircleStop: '\uF2DC',
      NavigateBack: '\uF2DD',
      NavigateBackMirrored: '\uF2DE',
      Repo: '\uF2CB',
      GitGraph: '\uF2CA',
      ImageCrosshair: '\uF2C9',
      BulletedList2Mirrored: '\uF2C8',
      CommentNext: '\uF2B4',
      CommentPrevious: '\uF2B5',
      ShopServer: '\uF2B6',
      LocaleLanguage: '\uF2B7',
      QueryList: '\uF2B8',
      UserSync: '\uF2B9',
      UserPause: '\uF2BA',
      StreamingOff: '\uF2BB',
      MoreVertical: '\uF2BC',
      NavigateForwardMirrored: '\uF2E0',
      ArrowTallUpLeft: '\uF2BD',
      ArrowTallDownLeft: '\uF2BF',
      ArrowTallDownRight: '\uF2C0',
      FieldEmpty: '\uF2C1',
      FieldFilled: '\uF2C2',
      FieldChanged: '\uF2C3',
      FieldNotChanged: '\uF2C4',
      RingerOff: '\uF2C5',
      PlayResume: '\uF2C6',
      BulletedList2: '\uF2C7',
      ArrowTallUpRight: '\uF2BE',
      FolderOpen: '\uE838',
      ProvisioningPackage: '\uE835',
      CortanaLogoInner: '\uE832',
      PartlySunnyShowersNight: '\uE47A',
      MostyCloudyTStormsNight: '\uE47B',
      PartlySunnyTStormsNight: '\uE47C',
      MostyCloudyFlurriesNight: '\uE47D',
      Sharepoint2013LogoInverse: '\uE480',
      OutlookSpacesBucket: '\uE481',
      CaretSolidAlt: '\uE483',
      PowerVirtualAgentsLogo: '\uE484',
      FluidLogo: '\uE48A',
      FormProcessing: '\uE48B',
      CategoryClassification: '\uE48C',
      PhishingCampaign: '\uE48D',
      PhishingHook: '\uE48E',
      SecurityTest: '\uE48F',
      OfficeCatchUp: '\uE490',
      DynamicList: '\uE491',
      GroupRemove: '\uE495',
      ReceiptProcessing: '\uE496',
      InboxActive: '\uE497',
      HazyNight: '\uE479',
      RingerActive: '\uE498',
      IntermittentCloudsNight: '\uE478',
      MostyClearNight: '\uE476',
      AssignPolicy: '\uE461',
      CalculatorGroup: '\uE462',
      MobileAngled: '\uE463',
      CopyEdit: '\uE464',
      EntityExtraction: '\uE467',
      MostySunnyDay: '\uE468',
      PartlySunnyDay: '\uE469',
      IntermittentCloudsDay: '\uE46A',
      HazyDay: '\uE46B',
      Showers: '\uE46C',
      MostyCloudyShowersDay: '\uE46D',
      PartlySunnyShowersDay: '\uE46E',
      MostyCloudyTStormsDay: '\uE46F',
      PartlySunnyTStormsDay: '\uE470',
      MostyCloudyFlurriesDay: '\uE471',
      PartlySunnyFlurriesDay: '\uE472',
      Ice: '\uE473',
      Sleet: '\uE474',
      FreezingRain: '\uE475',
      PartlyClearNight: '\uE477',
      Gather: '\uE460',
      PopExpand: '\uE49A',
      TextDocumentSettings: '\uE4AA',
      MetricsInstall: '\uE4CD',
      MetricsFailure: '\uE4CE',
      CommunicationDetails: '\uE4CF',
      CommunicationDetailsMirrored: '\uE4D0',
      Variable3: '\uE4D1',
      SettingsSecure: '\uE4D2',
      LightningSecure: '\uE4D3',
      MailSecure: '\uE4D4',
      CloudSecure: '\uE4D5',
      IoTSecure: '\uE4D6',
      TestAdd: '\uE4DC',
      WebAppBuilderFragmentLock: '\uE4DD',
      WebAppBuilderFragmentTest: '\uE4DE',
      WebAppBuilderModuleLock: '\uE4DF',
      WebAppBuilderModuleTest: '\uE4E0',
      TestRemove: '\uE4E1',
      SamsungGallery: '\uE4E2',
      PBIDirectQuery: '\uE4E9',
      MetricsUsage: '\uE4CC',
      FlowTemplate: '\uE49C',
      OrderLock: '\uE4CB',
      CloudLink: '\uE4C9',
      PowerAppsTemplate: '\uE4AC',
      TemporaryAccessPass: '\uE4AD',
      ChoiceColumn: '\uE4AE',
      CtrlButton: '\uE4B8',
      DensityDefault: '\uE4B9',
      DensityComfy: '\uE4BA',
      ODLink: '\uE4BB',
      ODLink12: '\uE4BC',
      TableColumn: '\uE4BD',
      CalculatedTable: '\uE4BE',
      ColumnSigma: '\uE4BF',
      ColumnQuestion: '\uE4C0',
      ColumnQuestionMirrored: '\uE4C1',
      ColumnFunction: '\uE4C2',
      PhotoBlock: '\uE4C3',
      EditTable: '\uE4C4',
      AddTable: '\uE4C6',
      AddField: '\uE4C7',
      CloudEdit: '\uE4C8',
      ExternalUser: '\uE4CA',
      LightningBoltSolid: '\uE45F',
      FabricFolderLink: '\uE45C',
      LockShare: '\uE455',
      TextParagraphOption: '\uE3E3',
      TextRotation: '\uE3E4',
      TextRotateHorizontal: '\uE3E5',
      TextRotate90Degrees: '\uE3E6',
      TextRotate270Degrees: '\uE3E7',
      AutoFitContents: '\uE3E8',
      AutoFitWindow: '\uE3E9',
      FixedColumnWidth: '\uE3EA',
      RepeatHeaderRows: '\uE3EB',
      TableHeaderRow: '\uE3EC',
      TableTotalRow: '\uE3ED',
      TableBrandedRow: '\uE3EE',
      TableFirstColumn: '\uE3EF',
      TableLastColumn: '\uE3F0',
      TableBrandedColumn: '\uE3F1',
      AutomateFlow: '\uE3F5',
      TagGroup: '\uE3F6',
      PhotoError: '\uE3F7',
      HexaditeInvestigation: '\uE3F8',
      TextAlignBottom: '\uE3E2',
      HexaditeInvestigationCancel: '\uE3F9',
      TextAlignMiddle: '\uE3E1',
      AddSpaceAfter: '\uE3DF',
      DecreaseIndent: '\uE39B',
      DecreaseIndentMirrored: '\uE39C',
      IncreaseIndentHanging: '\uE39D',
      IncreaseIndentHangingMirrored: '\uE39E',
      ArchiveUndo: '\uE3A1',
      LeaveUser: '\uE3A8',
      FabricFolderUpload: '\uE3AC',
      RedEye12: '\uE3AD',
      MapPin12: '\uE3AE',
      Insights: '\uE3AF',
      TaskList: '\uE3B6',
      MachineLearning: '\uE3B8',
      HealthRefresh: '\uE3BD',
      FontSize2: '\uE3C0',
      SharedDatabase: '\uE3D9',
      WebEnvironment: '\uE3DB',
      FormatPainter: '\uE3DC',
      IndentFirstLine: '\uE3DD',
      AddSpaceBefore: '\uE3DE',
      TextAlignTop: '\uE3E0',
      HexaditeInvestigationSemiAuto: '\uE3FA',
      DriverOff: '\uE3FB',
      FileOff: '\uE3FC',
      BlobStorage: '\uE436',
      DatabaseView: '\uE437',
      Column: '\uE438',
      AzureDataExplorer: '\uE439',
      SortLinesAscending: '\uE43A',
      TextDocumentEdit: '\uE43B',
      VideoOff2: '\uE43C',
      FastForwardPointFiveX: '\uE43E',
      FastForwardOneX: '\uE43F',
      FastForwardOneFiveX: '\uE440',
      FastForwardTwoX: '\uE441',
      FastForwardFourX: '\uE442',
      FastForwardEightX: '\uE443',
      RewindPointFiveX: '\uE444',
      RewindOneX: '\uE445',
      RewindOneFiveX: '\uE446',
      RewindTwoX: '\uE447',
      RewindFourX: '\uE448',
      RewindEightX: '\uE449',
      StorageAcount: '\uE435',
      SQLAnalyticsPool: '\uE434',
      FileSystem: '\uE433',
      D365ProjectOperations: '\uE432',
      ServiceOff: '\uE3FD',
      URLBlock: '\uE3FE',
      BugSync: '\uE3FF',
      BugBlock: '\uE400',
      DeviceRun: '\uE401',
      DeviceOff: '\uE402',
      ProcessingCancel: '\uE403',
      ProcessingRun: '\uE404',
      ProcessingPause: '\uE405',
      PBIDual: '\uE4EA',
      CalculatorDelta: '\uE406',
      ViewInAR: '\uE41F',
      ImageInAR: '\uE420',
      CubeShapeSolid: '\uE421',
      ShapeSolid: '\uE422',
      Reset: '\uE423',
      DeviceBug: '\uE424',
      HomeDropdown: '\uE427',
      ContentFeed: '\uE428',
      CompareUneven: '\uE42E',
      InputAddress: '\uE41E',
      PBIImport: '\uE4EB',
      PBILiveConnect: '\uE4EC',
      UserWindow: '\uE4ED',
      Contrast: '\uE7A1',
      Redo: '\uE7A6',
      Undo: '\uE7A7',
      Crop: '\uE7A8',
      PhotoCollection: '\uE7AA',
      Album: '\uE7AB',
      OpenWith: '\uE7AC',
      Rotate: '\uE7AD',
      PanoIndicator: '\uE7B0',
      Translate: '\uE7B2',
      RedEye: '\uE7B3',
      ViewOriginal: '\uE7B4',
      ThumbnailView: '\uE7B6',
      Package: '\uE7B8',
      Telemarketer: '\uE7B9',
      Warning: '\uE7BA',
      Financial: '\uE7BB',
      Education: '\uE7BE',
      ShoppingCart: '\uE7BF',
      AspectRatio: '\uE799',
      Train: '\uE7C0',
      Filters: '\uE795',
      SaveAs: '\uE792',
      EaseOfAccess: '\uE776',
      UpdateRestore: '\uE777',
      ContactInfo: '\uE779',
      Unpin: '\uE77A',
      Contact: '\uE77B',
      Memo: '\uE77C',
      IncomingCall: '\uE77E',
      Paste: '\uE77F',
      WindowsLogo: '\uE782',
      Error: '\uE783',
      GripperBarVertical: '\uE784',
      Unlock: '\uE785',
      Slideshow: '\uE786',
      Calendar: '\uE787',
      Megaphone: '\uE789',
      Trim: '\uE78A',
      AutoEnhanceOn: '\uE78D',
      AutoEnhanceOff: '\uE78E',
      Color: '\uE790',
      Light: '\uE793',
      Flag: '\uE7C1',
      Move: '\uE7C2',
      Page: '\uE7C3',
      SwitcherStartEnd: '\uE810',
      ParkingLocation: '\uE811',
      IncidentTriangle: '\uE814',
      Touch: '\uE815',
      MapDirections: '\uE816',
      CaretHollow: '\uE817',
      CaretSolid: '\uE818',
      History: '\uE81C',
      Location: '\uE81D',
      MapLayers: '\uE81E',
      SearchNearby: '\uE820',
      Work: '\uE821',
      Recent: '\uE823',
      Hotel: '\uE824',
      Bank: '\uE825',
      LocationDot: '\uE827',
      Dictionary: '\uE82D',
      ChromeBack: '\uE830',
      CortanaLogoOuter: '\uE831',
      Home: '\uE80F',
      LocationCircle: '\uE80E',
      SeeDo: '\uE808',
      EatDrink: '\uE807',
      TouchPointer: '\uE7C9',
      Merge: '\uE7D5',
      TurnRight: '\uE7DB',
      Ferry: '\uE7E3',
      Highlight: '\uE7E6',
      PowerButton: '\uE7E8',
      Tab: '\uE7E9',
      Admin: '\uE7EF',
      CC: '\uE7F0',
      Globe: '\uE774',
      SDCard: '\uE7F1',
      Speakers: '\uE7F5',
      Game: '\uE7FC',
      HorizontalTabKey: '\uE7FD',
      UnstackSelected: '\uE7FE',
      StackIndicator: '\uE7FF',
      Nav2DMapView: '\uE800',
      StreetsideSplitMinimize: '\uE802',
      Car: '\uE804',
      Bus: '\uE806',
      TVMonitor: '\uE7F4',
      IncreaseIndentMirrored: '\uE39A',
      SearchAndApps: '\uE773',
      System: '\uE770',
      Pin: '\uE718',
      Shop: '\uE719',
      Stop: '\uE71A',
      Link: '\uE71B',
      Filter: '\uE71C',
      AllApps: '\uE71D',
      Zoom: '\uE71E',
      ZoomOut: '\uE71F',
      Microphone: '\uE720',
      Search: '\uE721',
      Camera: '\uE722',
      Attach: '\uE723',
      Send: '\uE724',
      FavoriteList: '\uE728',
      PageSolid: '\uE729',
      Forward: '\uE72A',
      Back: '\uE72B',
      Refresh: '\uE72C',
      Share: '\uE72D',
      Phone: '\uE717',
      Lock: '\uE72E',
      People: '\uE716',
      Video: '\uE714',
      ObjectRecognition: '\uE4EE',
      ClipboardListAdd: '\uE4EF',
      ClipboardListQuestion: '\uE4F0',
      ClipboardListReply: '\uE4F1',
      SurveyQuestionResponse: '\uE4F2',
      GlobalNavButton: '\uE700',
      InternetSharing: '\uE704',
      Brightness: '\uE706',
      MapPin: '\uE707',
      Airplane: '\uE709',
      Tablet: '\uE70A',
      QuickNote: '\uE70B',
      ChevronDown: '\uE70D',
      ChevronUp: '\uE70E',
      Edit: '\uE70F',
      Add: '\uE710',
      Cancel: '\uE711',
      More: '\uE712',
      Settings: '\uE713',
      Mail: '\uE715',
      BlockedSite: '\uE72F',
      ReportHacked: '\uE730',
      EMI: '\uE731',
      Flashlight: '\uE754',
      CommandPrompt: '\uE756',
      Sad: '\uE757',
      RealEstate: '\uE758',
      SIPMove: '\uE759',
      EraseTool: '\uE75C',
      GripperTool: '\uE75E',
      Dialpad: '\uE75F',
      PageLeft: '\uE760',
      PageRight: '\uE761',
      MultiSelect: '\uE762',
      KeyboardClassic: '\uE765',
      Play: '\uE768',
      Pause: '\uE769',
      ChevronLeft: '\uE76B',
      ChevronRight: '\uE76C',
      InkingTool: '\uE76D',
      Emoji2: '\uE76E',
      GripperBarHorizontal: '\uE76F',
      Cloud: '\uE753',
      ReturnKey: '\uE751',
      Save: '\uE74E',
      Delete: '\uE74D',
      MiniLink: '\uE732',
      Blocked: '\uE733',
      FavoriteStar: '\uE734',
      FavoriteStarFill: '\uE735',
      ReadingMode: '\uE736',
      Favicon: '\uE737',
      Remove: '\uE738',
      Checkbox: '\uE739',
      CheckboxComposite: '\uE73A',
      Personalize: '\uE771',
      CheckboxFill: '\uE73B',
      CheckboxCompositeReversed: '\uE73D',
      CheckMark: '\uE73E',
      BackToWindow: '\uE73F',
      FullScreen: '\uE740',
      SwitchUser: '\uE748',
      Print: '\uE749',
      Up: '\uE74A',
      Down: '\uE74B',
      OEM: '\uE74C',
      CheckboxIndeterminate: '\uE73C',
      IncreaseIndent: '\uE399',
      AltText: '\uE397',
      DecreaseIndentLegacy: '\uE290',
      IncreaseIndentLegacy: '\uE291',
      SettingsAdd: '\uE35A',
      AddLink: '\uE35E',
      SharepointAppIcon16: '\uE365',
      DataflowsLink: '\uE366',
      TimePicker: '\uE367',
      UserWarning: '\uE368',
      ComplianceAudit: '\uE369',
      StickyNotesOutlineAppIcon: '\uE36A',
      StickyNotesSolidAppIcon: '\uE36B',
      Webcam2Off: '\uE36D',
      eDiscovery: '\uE370',
      CalendarYear: '\uE371',
      IgnoreConversation: '\uE372',
      CommonDataServiceCDS: '\uE377',
      Forum: '\uE378',
      SentimentAnalysis: '\uE393',
      TextRecognition: '\uE394',
      KeyPhraseExtraction: '\uE395',
      SettingsSync: '\uE359',
      BugAction: '\uE358',
      BugWarning: '\uE357',
      PinSolidOff12: '\uE353',
      SizeLegacy: '\uE2B2',
      PageLink: '\uE302',
      CommentSolid: '\uE30E',
      ChangeEntitlements: '\uE310',
      Installation: '\uE311',
      WebAppBuilderModule: '\uE313',
      WebAppBuilderFragment: '\uE314',
      WebAppBuilderSlot: '\uE315',
      BullseyeTargetEdit: '\uE319',
      WebAppBuilderFragmentCreate: '\uE31B',
      PageHeaderEdit: '\uE31D',
      ProductList: '\uE31E',
      UnpublishContent: '\uE31F',
      DependencyAdd: '\uE344',
      DependencyRemove: '\uE345',
      EntitlementPolicy: '\uE346',
      EntitlementRedemption: '\uE347',
      SchoolDataSyncLogo: '\uE34C',
      PinSolid12: '\uE352',
      PageData: '\uE31C',
      NumberedListMirrored: '\uE398',
      StackedColumnChart2: '\uF666',
      QuadColumn: '\uF66F',
      ShieldAlert: '\uF7D7',
      StreamDiscover: '\uF7D9',
      FabricTextHighlightComposite: '\uF7DA',
      LearningTools: '\uF7DB',
      Dataflows: '\uF7DD',
      ShowGrid: '\uF7DE',
      DiagnosticDataBarTooltip: '\uF7DF',
      SaveToMobile: '\uF7E0',
      Orientation2: '\uF7E1',
      ScreenCast: '\uF7E2',
      GenericScanFilled: '\uF7E3',
      SnapToGrid: '\uF7E4',
      ContactList: '\uF7E5',
      NewMail: '\uF7EA',
      EyeShadow: '\uF7EB',
      RemoveFromShoppingList: '\uF7D5',
      M365InvoicingLogo: '\uF7C1',
      ReleaseGateCheck: '\uF7BF',
      ClearFormattingA: '\uF79D',
      ClearFormattingEraser: '\uF79E',
      Photo2Fill: '\uF79F',
      IncreaseIndentText: '\uF7A0',
      IncreaseIndentArrow: '\uF7A1',
      DecreaseIndentText: '\uF7A2',
      DecreaseIndentArrow: '\uF7A3',
      IncreaseIndentTextMirrored: '\uF7A4',
      IncreaseIndentArrowMirrored: '\uF7A5',
      DecreaseIndentTextMirrored: '\uF7A6',
      DecreaseIndentArrowMirrored: '\uF7A7',
      CheckListText: '\uF7A8',
      CheckListCheck: '\uF7A9',
      CheckListTextMirrored: '\uF7AA',
      CheckListCheckMirrored: '\uF7AB',
      NumberSymbol: '\uF7AC',
      Coupon: '\uF7BC',
      VerifiedBrand: '\uF7BD',
      ReleaseGate: '\uF7BE',
      ReleaseGateError: '\uF7C0',
      FabricTextHighlight: '\uF79C',
      FabricFolderConfirm: '\uF7FF',
      BarChartVerticalFill: '\uF830',
      StackedColumnChart2Fill: '\uF831',
      PlainText: '\uF834',
      AccessibiltyChecker: '\uF835',
      DatabaseSync: '\uF842',
      ReservationOrders: '\uF845',
      TabOneColumn: '\uF849',
      TabTwoColumn: '\uF84A',
      TabThreeColumn: '\uF84B',
      BulletedTreeList: '\uF84C',
      MicrosoftTranslatorLogoGreen: '\uF852',
      MicrosoftTranslatorLogoBlue: '\uF853',
      InternalInvestigation: '\uF854',
      AddReaction: '\uF85D',
      Header: '\uF82F',
      Footer: '\uF82E',
      MailOptions: '\uF82C',
      InformationBarriers: '\uF803',
      CommentActive: '\uF804',
      ColumnVerticalSectionEdit: '\uF806',
      WavingHand: '\uF807',
      ShakeDevice: '\uF80A',
      SmartGlassRemote: '\uF80B',
      Rotate90Clockwise: '\uF80D',
      Rotate90CounterClockwise: '\uF80E',
      CampaignTemplate: '\uF811',
      ChartTemplate: '\uF812',
      PageListFilter: '\uF813',
      SecondaryNav: '\uF814',
      VirtualNetwork: '\uF815',
      ColumnVerticalSection: '\uF81E',
      SkypeCircleSlash: '\uF825',
      SkypeSlash: '\uF826',
      CustomizeToolbar: '\uF828',
      DuplicateRow: '\uF82A',
      RemoveFromTrash: '\uF82B',
      Childof: '\uF82D',
      RemoveLinkX: '\uF79B',
      RemoveLinkChain: '\uF79A',
      NumberedListNumberMirrored: '\uF799',
      D365TalentLearn: '\uF6BB',
      D365TalentInsight: '\uF6BC',
      D365CoreHR: '\uF6BD',
      BacklogList: '\uF6BF',
      ButtonControl: '\uF6C0',
      BullseyeTargetDelete: '\uF6C1',
      TableGroup: '\uF6D9',
      MountainClimbing: '\uF6DB',
      TagUnknown: '\uF6DF',
      TagUnknownMirror: '\uF6E0',
      TagUnknown12: '\uF6E1',
      TagUnknown12Mirror: '\uF6E2',
      Link12: '\uF6E3',
      Presentation: '\uF6E4',
      Presentation12: '\uF6E5',
      Lock12: '\uF6E6',
      BuildDefinition: '\uF6E9',
      ReleaseDefinition: '\uF6EA',
      SaveTemplate: '\uF6EC',
      MedalSolid: '\uF6B9',
      UserGauge: '\uF6ED',
      DefenderTVM: '\uF6B3',
      AuthenticatorApp: '\uF6B1',
      WhiteBoardApp16: '\uF673',
      WhiteBoardApp32: '\uF674',
      PinnedSolid: '\uF676',
      InsertSignatureLine: '\uF677',
      ArrangeByFrom: '\uF678',
      Phishing: '\uF679',
      CreateMailRule: '\uF67A',
      Number: '\uF691',
      PublishCourse: '\uF699',
      DictionaryRemove: '\uF69A',
      UserRemove: '\uF69B',
      UserEvent: '\uF69C',
      Encryption: '\uF69D',
      LiveSite: '\uF6A6',
      PasswordField: '\uF6AA',
      OpenInNewTab: '\uF6AB',
      Hide3: '\uF6AC',
      VerifiedBrandSolid: '\uF6AD',
      MarkAsProtected: '\uF6AE',
      WebTemplate: '\uF6B2',
      BlockedSiteSolid12: '\uF70A',
      TagSolid: '\uF70E',
      OfficeChat: '\uF70F',
      TableLink: '\uF77A',
      PromotedDatabase: '\uF77D',
      BarChartVerticalFilter: '\uF77E',
      BarChartVerticalFilterSolid: '\uF77F',
      MicOff2: '\uF781',
      MicrosoftTranslatorLogo: '\uF782',
      ShowTimeAs: '\uF787',
      FileRequest: '\uF789',
      AcceptMedium: '\uF78C',
      WorkItemAlert: '\uF78F',
      PowerBILogo16: '\uF790',
      PowerBILogoBackplate16: '\uF791',
      BulletedListText: '\uF792',
      BulletedListBullet: '\uF793',
      BulletedListTextMirrored: '\uF794',
      BulletedListBulletMirrored: '\uF795',
      NumberedListText: '\uF796',
      NumberedListNumber: '\uF797',
      NumberedListTextMirrored: '\uF798',
      LinkedDatabase: '\uF779',
      AddIn: '\uF775',
      MailAttached: '\uF774',
      GripperDotsVertical: '\uF772',
      OfficeChatSolid: '\uF710',
      MailSchedule: '\uF72E',
      PowerShell2: '\uF730',
      WarningSolid: '\uF736',
      Blocked2Solid: '\uF737',
      SkypeCircleArrow: '\uF747',
      SkypeArrow: '\uF748',
      SyncStatus: '\uF751',
      SyncStatusSolid: '\uF752',
      ContactHeart: '\uF862',
      ProjectDocument: '\uF759',
      VisioDiagramSync: '\uF762',
      Event12: '\uF763',
      EventDateMissed12: '\uF764',
      UserOptional: '\uF767',
      ResponsesMenu: '\uF768',
      DoubleDownArrow: '\uF769',
      DistributeDown: '\uF76A',
      BookmarkReport: '\uF76B',
      FilterSettings: '\uF76C',
      ToDoLogoOutline: '\uF75B',
      VisuallyImpaired: '\uF866',
      EventToDoLogo: '\uF869',
      Variable2: '\uF86D',
      ModelingView: '\uF871',
      DisconnectVirtualMachine: '\uF873',
      ReportLock: '\uF875',
      Uneditable2: '\uF876',
      Uneditable2Mirrored: '\uF877',
      Spelling: '\uF87B',
      BarChartVerticalEdit: '\uF89D',
      GlobalNavButtonActive: '\uF89F',
      PollResults: '\uF8A0',
      Rerun: '\uF8A1',
      QandA: '\uF8A2',
      QandAMirror: '\uF8A3',
      BookAnswers: '\uF8A4',
      AlertSettings: '\uF8B6',
      TrimStart: '\uF8BB',
      TrimEnd: '\uF8BC',
      TableComputed: '\uF8F5',
      TripleColumnWide: '\uF66E',
    },
  };

  registerIcons(subset, options);
}
