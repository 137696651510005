import React from 'react';
import { Icon, Link } from '@fluentui/react';

import bulletSrc from './bullet.png';
import { useTheme } from '../../theme';

export const List = ({
  links,
  ariaDescribedBy,
}: {
  links: { name: string; href: string }[];
  ariaDescribedBy: string;
}) => {
  const theme = useTheme();
  return (
    <ul
      style={{
        listStyleImage: `url('${bulletSrc}')`,
        paddingLeft: 21,
        margin: '0',
      }}
    >
      {links.map((l) => (
        <li key={l.href} style={{ padding: '0 30px 16px 4px' }}>
          <Link
            href={l.href}
            target="_blank"
            styles={{ root: { color: theme.palette.neutralPrimary } }}
            aria-describedby={ariaDescribedBy}
          >
            {l.name}
            <Icon
              style={{
                paddingLeft: 5,
                color: theme.palette.themePrimary,
              }}
              iconName="NavigateExternalInline"
            />
          </Link>
        </li>
      ))}
    </ul>
  );
};
