import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AnimationStyles,
  FontWeights,
  Icon,
  mergeStyles,
  PrimaryButton,
  Stack,
  Text,
  Image,
} from '@fluentui/react';

import { useIsSmallScreen } from '../useIsSmallScreen';
import { useTheme } from '../../theme';

import placeholderSrc from './assets/placeholder.png';

const fadeInAnimationClass = mergeStyles(AnimationStyles.slideDownIn20);

export const TourAccordion = ({
  name,
  link,
  description,
  picture,
  isOpen,
  setIsOpen,
}: {
  name: string;
  link?: string;
  description: string | JSX.Element;
  picture?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isSmallScreen = useIsSmallScreen();
  return (
    <Stack
      styles={{
        root: {
          borderBottom: `1px solid ${palette.neutralLight}`,
          padding: isSmallScreen ? 0 : '0 20px',
        },
      }}
    >
      <Stack
        as={'button'}
        horizontal
        verticalAlign="center"
        horizontalAlign="space-between"
        tokens={{ childrenGap: 20 }}
        styles={{
          root: {
            cursor: 'pointer',
            padding: isSmallScreen ? '16px 0' : '32px 0',
            background: 'none',
            border: 'none',
          },
        }}
        aria-expanded={isOpen}
        title={isOpen ? t('Close') : t('Open')}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Text
          variant={isSmallScreen ? 'mediumPlus' : 'xLarge'}
          styles={{ root: { fontWeight: FontWeights.semibold } }}
        >
          {name}
        </Text>
        <Icon
          styles={{ root: { color: palette.neutralDark } }}
          iconName={isOpen ? 'Remove' : 'Add'}
        />
      </Stack>
      {isOpen && (
        <Stack
          className={fadeInAnimationClass}
          tokens={{ childrenGap: 20 }}
          styles={{
            root: {
              marginTop: `${isSmallScreen ? -4 : -18}px !important`,
              marginBottom: 20,
            },
          }}
        >
          <Stack.Item>
            <Text variant={isSmallScreen ? 'medium' : 'mediumPlus'}>
              {description}
            </Text>
          </Stack.Item>
          {isSmallScreen && (
            <Stack.Item>
              <Image
                src={picture ?? placeholderSrc}
                styles={{ image: { width: '100%' } }}
                alt={name}
              />
            </Stack.Item>
          )}
          <Stack.Item>
            {link ? (
              <PrimaryButton
                target="_blank"
                href={link}
                iconProps={{ iconName: 'Forward' }}
              >
                {t('Start tour now')}
              </PrimaryButton>
            ) : (
              <Text
                variant={isSmallScreen ? 'medium' : 'mediumPlus'}
                styles={{ root: { color: palette.neutralSecondary } }}
              >
                {t('Coming soon...')}
              </Text>
            )}
          </Stack.Item>
        </Stack>
      )}
    </Stack>
  );
};
