import React from 'react';
import { MessageBar, MessageBarButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

import { useIsSmallScreen } from '../useIsSmallScreen';

import commerceIconSrc from './commerce.svg';

export const ContactUs = () => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  return (
    <MessageBar
      messageBarIconProps={{ imageProps: { src: commerceIconSrc } }}
      isMultiline={isSmallScreen}
      actions={
        <MessageBarButton
          target="_blank"
          href="https://aka.ms/CommerceShowcase/contactSales"
        >
          {t('Contact sales')}
        </MessageBarButton>
      }
    >
      {t('Contact us to learn more about Dynamics 365 Commerce.')}
    </MessageBar>
  );
};
