import React, { useState } from 'react';
import { Stack } from '@fluentui/react';

import { Sidebar } from '../Sidebar';
import { Header } from '../Header';
import { ContactUs } from './ContactUs';
import { Hero } from './Hero';
import { Demos } from './Demos';
import { Tours } from './Tours';
import { Learn } from './Learn';
import { Links } from './Links';
import { useIsSmallScreen } from './useIsSmallScreen';
import { useTheme } from '../theme';

export const LandingPage = () => {
  const { palette } = useTheme();
  const isSmallScreen = useIsSmallScreen();

  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(true);

  return (
    <Stack verticalFill>
      <Stack.Item>
        <Header
          onBurgerButtonClick={() => setSidebarIsCollapsed((state) => !state)}
        />
      </Stack.Item>
      <Stack
        grow
        horizontal
        styles={{ root: { minHeight: 0, position: 'relative' } }}
      >
        <Stack.Item>
          <Sidebar
            isCollapsed={sidebarIsCollapsed}
            setIsCollapsed={setSidebarIsCollapsed}
          />
        </Stack.Item>
        <Stack grow styles={{ root: { overflowY: 'auto' } }}>
          <Stack.Item>
            <ContactUs />
          </Stack.Item>
          <main role="main" id="main-content-landing-react">
            <Stack grow tokens={{ childrenGap: isSmallScreen ? 40 : 60 }}>
              <Stack
                horizontalAlign="center"
                styles={{
                  root: { backgroundColor: palette.neutralLighterAlt },
                }}
              >
                <Stack styles={{ root: { maxWidth: 1440 } }}>
                  <Hero />
                </Stack>
              </Stack>
              <Stack
                styles={{
                  root: { padding: isSmallScreen ? '0 16px' : '0 32px' },
                }}
                horizontalAlign="center"
              >
                <Stack
                  styles={{ root: { maxWidth: 1440, paddingBottom: 50 } }}
                  tokens={{ childrenGap: isSmallScreen ? 40 : 60 }}
                >
                  <Demos />
                  <Tours />
                  <Learn />
                  <Links />
                </Stack>
              </Stack>
            </Stack>
          </main>
        </Stack>
      </Stack>
    </Stack>
  );
};
