import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontWeights, Image, Stack, Text } from '@fluentui/react';

import { useIsSmallScreen } from '../useIsSmallScreen';
import { hoverTargetClass, UnstyledLink } from '../../uiKit/UnstyledLink';
import { useTheme } from '../../theme';

export const Article = ({
  name,
  link,
  icon,
  complexity,
  duration,
  id,
}: {
  name: string;
  link: string;
  icon: string;
  complexity: string;
  duration: string;
  id: string;
}) => {
  const { t } = useTranslation();
  const { palette, effects } = useTheme();
  const isSmallScreen = useIsSmallScreen();
  const firstCaption = `firstCaption-${id}`;
  return (
    <UnstyledLink href={link} target="_blank">
      <Stack
        grow
        horizontal
        styles={{
          root: {
            height: 160,
            borderRadius: effects.roundedCorner2,
            boxShadow: effects.elevation4,
          },
        }}
      >
        <Stack
          horizontalAlign="center"
          verticalAlign="center"
          styles={{
            root: {
              backgroundColor: palette.themeLighter,
              borderTopLeftRadius: effects.roundedCorner2,
              borderBottomLeftRadius: effects.roundedCorner2,
              minWidth: isSmallScreen ? 120 : 180,
              width: isSmallScreen ? 120 : 180,
            },
          }}
        >
          <Image src={icon} alt={name} aria-labelledby={firstCaption} />
        </Stack>
        <Stack
          grow
          verticalAlign="space-between"
          styles={{
            root: {
              padding: 12,
              borderTopRightRadius: effects.roundedCorner2,
              borderBottomRightRadius: effects.roundedCorner2,
            },
          }}
        >
          <Stack tokens={{ childrenGap: 12 }}>
            <Text
              id={firstCaption}
              variant="small"
              styles={{
                root: {
                  fontWeight: FontWeights.semibold,
                  color: palette.themePrimary,
                },
              }}
            >
              {t('Microsoft Learn')}
            </Text>
            <Text
              className={hoverTargetClass}
              styles={{ root: { fontWeight: FontWeights.semibold } }}
            >
              {name}
            </Text>
          </Stack>
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
          >
            <Text
              variant="small"
              styles={{
                root: {
                  padding: 6,
                  color: palette.neutralSecondary,
                  backgroundColor: palette.neutralLight,
                  borderRadius: effects.roundedCorner2,
                },
              }}
            >
              {complexity}
            </Text>
            <Text
              variant="small"
              styles={{ root: { color: palette.neutralSecondary } }}
            >
              {duration}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </UnstyledLink>
  );
};
