import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react';

import { Heading } from '../Heading';
import { List } from './List';
import { useIsSmallScreen } from '../useIsSmallScreen';

const useDocumentationLinks = () => {
  const { t } = useTranslation();
  return [
    {
      name: t('Microsoft Docs for Dynamics 365 Commerce'),
      href: 'https://docs.microsoft.com/en-us/dynamics365/commerce/',
    },
    {
      name: t('Dynamics 365 Commerce architecture overview'),
      href: 'https://docs.microsoft.com/en-us/dynamics365/commerce/commerce-architecture',
    },
    {
      name: t('Channels overview'),
      href: 'https://docs.microsoft.com/en-us/dynamics365/commerce/channels-overview',
    },
    {
      name: t('E-commerce site overview'),
      href: 'https://docs.microsoft.com/en-us/dynamics365/commerce/online-store-overview',
    },
    {
      name: t('Commerce development and extensibility'),
      href: 'https://docs.microsoft.com/en-us/dynamics365/commerce/dev-itpro/dev-retail-home-page',
    },
  ];
};

const useCommunityLinks = () => {
  const { t } = useTranslation();
  return [
    {
      name: t('Commerce Community Forums'),
      href: 'https://community.dynamics.com/365/commerce/f/dynamics-365-commerce-forum',
    },
    {
      name: t('Read Community blogs for Commerce'),
      href: 'https://community.dynamics.com/365/commerce/p/allblogs?wt.mc_id=d365commercecard2-commblogs',
    },
    {
      name: t('Watch videos for Commerce'),
      href: 'https://community.dynamics.com/365/commerce/b/dynamics-365-commerce-videos?wt.mc_id=d365commercecard2-videos',
    },
    {
      name: t('View Webinars for Commerce'),
      href: 'https://community.dynamics.com/365/b/webinars?c=Commerce',
    },
    {
      name: t('View the latest Commerce TechTalks'),
      href: 'https://community.dynamics.com/365/b/techtalks',
    },
    {
      name: t('Submit ideas for Commerce'),
      href: 'https://experience.dynamics.com/ideas/categories/?forum=fd509429-7e87-e711-8105-c4346bb5988c&forumName=Dynamics%20365%20Commerce',
    },
    {
      name: t('FastTrack Program'),
      href: 'https://dynamics.microsoft.com/en-us/fasttrack/',
    },
  ];
};

export const Links = () => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const documentationLinks = useDocumentationLinks();
  const communityLinks = useCommunityLinks();
  return (
    <Stack horizontal={!isSmallScreen} tokens={{ childrenGap: 24 }}>
      <Stack.Item grow>
        <Heading title={t('Documentation')} as={'h2'} id={'Documentation'} />
        <List links={documentationLinks} ariaDescribedBy={'Documentation'} />
      </Stack.Item>
      <Stack.Item grow>
        <Heading title={t('Community')} as={'h2'} id={'Community'} />
        <List links={communityLinks} ariaDescribedBy={'Community'} />
      </Stack.Item>
    </Stack>
  );
};
