import React from 'react';
import { getId, ILinkProps, Link, mergeStyleSets } from '@fluentui/react';

export const hoverTargetClass = getId('hoverTarget');

export const UnstyledLink = (props: ILinkProps) => {
  const styles = mergeStyleSets(
    {
      root: {
        color: 'inherit !important',
        textDecoration: 'inherit !important',
        [`:hover .${hoverTargetClass}`]: {
          textDecoration: 'underline',
        },
      },
    },
    props.styles
  );
  return <Link {...props} styles={styles} />;
};
