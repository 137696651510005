import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image, Stack, Text } from '@fluentui/react';

import { useIsSmallScreen } from '../useIsSmallScreen';
import { useTheme } from '../../theme';

import heroPictureSrc from './hero.svg';

export const Hero = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const isSmallScreen = useIsSmallScreen();
  return (
    <Stack
      grow
      horizontal={!isSmallScreen}
      horizontalAlign="space-between"
      verticalAlign="center"
      styles={{
        root: isSmallScreen
          ? {
              padding: '24px 16px 0',
            }
          : {
              height: 340,
              padding: '0 40px 0',
              flexDirection: 'row-reverse',
            },
      }}
    >
      <Stack
        styles={
          isSmallScreen
            ? undefined
            : { root: { maxWidth: '50%', marginLeft: 20 } }
        }
      >
        <Stack
          tokens={{ childrenGap: isSmallScreen ? 12 : 20 }}
          styles={{ root: { padding: isSmallScreen ? undefined : '71px 0' } }}
        >
          <Text
            as={'h1'}
            variant={isSmallScreen ? 'xLargePlus' : 'xxLargePlus'}
            styles={{ root: { margin: 0 } }}
          >
            {t('Welcome to Dynamics 365 Commerce')}
          </Text>
          <Text
            variant={isSmallScreen ? 'medium' : 'mediumPlus'}
            styles={{ root: { color: palette.neutralSecondary } }}
          >
            {t(
              'Dynamics 365 Commerce delivers a complete omni-channel solution that unifies back-office, in-store, and digital experiences to personalize customer engagement, increase employee productivity, optimize operations, and deliver better business outcomes.'
            )}
          </Text>
        </Stack>
      </Stack>
      <Stack grow verticalAlign="end" horizontalAlign="center" verticalFill>
        <Image
          styles={
            isSmallScreen
              ? { image: { width: '100%', maxWidth: 360 } }
              : { root: { width: '100%' }, image: { width: '100%' } }
          }
          src={heroPictureSrc}
          aria-hidden
        />
      </Stack>
    </Stack>
  );
};
