import React from 'react';
import { FontWeights, ITextProps, Stack, Text } from '@fluentui/react';

import { useIsSmallScreen } from './useIsSmallScreen';
import { useTheme } from '../theme';

export const Heading = ({
  title,
  subtitle,
  as,
  id,
}: {
  title: string;
  subtitle?: string;
  as?: ITextProps['as'];
  id?: string;
}) => {
  const { palette } = useTheme();
  const isSmallScreen = useIsSmallScreen();
  return (
    <Stack>
      <Text
        variant="large"
        styles={{
          root: {
            fontWeight: FontWeights.semibold,
            paddingBottom: 8,
            margin: 0,
          },
        }}
        as={as}
        id={id}
      >
        {title}
      </Text>
      {subtitle && (
        <Text
          variant={isSmallScreen ? 'medium' : 'mediumPlus'}
          styles={{ root: { color: palette.neutralSecondary } }}
        >
          {subtitle}
        </Text>
      )}
    </Stack>
  );
};
