import React from 'react';
import { Stack } from '@fluentui/react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../theme';
import { Article } from './Article';
import { Heading } from '../Heading';

import getStartedIconSrc from './get-started.svg';
import fundamentalsIconSrc from './fundamentals.svg';
import engagementIconSrc from './engagement.svg';

const useArticles = () => {
  const { t } = useTranslation();
  return [
    {
      id: '1',
      name: t('Get started with Dynamics 365 Commerce'),
      link: 'https://docs.microsoft.com/en-us/learn/paths/get-started-dynamics-365-commerce/',
      icon: getStartedIconSrc,
      complexity: t('Beginner'),
      duration: '2hr 40min',
    },
    {
      id: '2',
      name: t('Learn the fundamentals of Microsoft Dynamics 365 Commerce'),
      link: 'https://docs.microsoft.com/en-us/learn/paths/learn-fundamentals-microsoft-dynamics-365-commerce/',
      icon: fundamentalsIconSrc,
      complexity: t('Beginner'),
      duration: '2hr 16min',
    },
    {
      id: '3',
      name: t(
        'Manage customer engagement and shopping experience in Dynamics 365 Commerce'
      ),
      link: 'https://docs.microsoft.com/en-us/learn/paths/manage-customer-engagement-shopping-experience-commerce/',
      icon: engagementIconSrc,
      complexity: t('Beginner'),
      duration: '4hr 3min',
    },
  ];
};

export const Learn = () => {
  const { t } = useTranslation();
  const isEnoughSpace = useMediaQuery({ query: '(min-width: 1400px)' });
  const articles = useArticles();
  const { palette } = useTheme();
  return (
    <Stack>
      <Heading title={t('Learn more')} as={'h2'} />
      <Stack horizontal={isEnoughSpace} tokens={{ childrenGap: 20 }}>
        {articles.map(({ id, ...props }) => (
          <Stack
            key={id}
            grow
            styles={{
              root: {
                width: '100%',
                selectors: {
                  '@media screen and (forced-colors: active)': {
                    borderColor: palette.white,
                    borderWidth: 'thin',
                    borderStyle: 'solid',
                  },
                },
              },
            }}
          >
            <Article id={id} {...props} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
