import { useTranslation } from 'react-i18next';

import adventureWorksSrc from './adventureWorks.png';
import adventureWorksBusinessSrc from './adventureWorksBusiness.png';

export const useData = () => {
  const { t } = useTranslation();
  return [
    {
      key: '1',
      name: t('Adventure Works'),
      link: 'https://aka.ms/CommerceShowcase/b2c',
      description: t(
        'Experience shopping direct to consumer with the Adventure Works sporting goods digital store.'
      ),
      picture: adventureWorksSrc,
    },
    {
      key: '2',
      name: t('Adventure Works Business'),
      link: 'https://aka.ms/CommerceShowcase/b2b',
      description: t(
        'Experience shopping direct to business with the Adventure Works Business sporting goods digital store.'
      ),
      picture: adventureWorksBusinessSrc,
    },
  ];
};
