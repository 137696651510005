import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontWeights, Image, Stack, Text } from '@fluentui/react';

import { useIsSmallScreen } from '../useIsSmallScreen';
import { Heading } from '../Heading';
import { UnstyledLink, hoverTargetClass } from '../../uiKit/UnstyledLink';
import { useData } from './data';
import { useTheme } from '../../theme';

const Demo = ({
  name,
  link,
  picture,
  description,
}: {
  name: string;
  link: string;
  picture: string;
  description: string;
}) => {
  const { palette, effects } = useTheme();
  return (
    <UnstyledLink target="_blank" href={link}>
      <Stack
        grow
        styles={{
          root: {
            borderRadius: effects.roundedCorner4,
            boxShadow: effects.elevation4,
          },
        }}
      >
        <Stack.Item
          styles={{
            root: { borderBottom: `1px solid ${palette.neutralLight}` },
          }}
        >
          <Image
            width="100%"
            src={picture}
            alt={name}
            styles={{
              image: {
                borderTopLeftRadius: effects.roundedCorner4,
                borderTopRightRadius: effects.roundedCorner4,
              },
            }}
          />
        </Stack.Item>
        <Stack
          grow
          tokens={{ childrenGap: 4 }}
          styles={{
            root: {
              padding: '12px 16px 16px',
              borderBottomLeftRadius: effects.roundedCorner2,
              borderBottomRightRadius: effects.roundedCorner2,
            },
          }}
        >
          <Text
            className={hoverTargetClass}
            styles={{ root: { fontWeight: FontWeights.semibold } }}
          >
            {name}
          </Text>
          <Text
            variant="small"
            styles={{ root: { color: palette.neutralSecondary } }}
          >
            {description}
          </Text>
        </Stack>
      </Stack>
    </UnstyledLink>
  );
};

export const Demos = () => {
  const { t } = useTranslation();
  const isSmallScreen = useIsSmallScreen();
  const data = useData();
  return (
    <Stack tokens={{ childrenGap: isSmallScreen ? 16 : 20 }}>
      <Heading
        title={t('Explore a trial experience')}
        subtitle={t(
          'Select one of our trial e-commerce sites below to experience Dynamics 365 Commerce.'
        )}
        as={'h2'}
      />
      <Stack horizontal={!isSmallScreen} tokens={{ childrenGap: 20 }}>
        {data.map((item) => (
          <Demo {...item} />
        ))}
      </Stack>
    </Stack>
  );
};
