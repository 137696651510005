import { useTranslation } from 'react-i18next';

import productDiscoveryPictureSrc from './assets/productDiscovery.png';
import curbSidePickupPictureSrc from './assets/curbSidePickup.png';
import intelligentRecommendationsPictureSrc from './assets/intelligentRecommendations.png';
import ratingsAndReviewsPictureSrc from './assets/ratingsAndReviews.png';
import quickAddToCartPictureSrc from './assets/quickAddToCart.png';
import customerServicePictureSrc from './assets/customerServices.png';
import customerVoicePictureSrc from './assets/customerVoice.png';
import signUpPictureSrc from './assets/signUp.png';
import quickOrderEntryPictureSrc from './assets/quickOrderEntry.png';
import viewAccountInfoPictureSrc from './assets/viewAccountInfo.png';

export type Tour = {
  key: string;
  name: string;
  link?: string;
  picture?: string;
  description: string | JSX.Element;
};

type Data = { key: string; name: string; tours: Tour[] }[];

export const useData: () => Data = () => {
  const { t } = useTranslation();
  return [
    {
      key: 'adventureWorks',
      name: t('Adventure Works'),
      tours: [
        {
          key: 'productDiscovery',
          picture: productDiscoveryPictureSrc,
          name: t('Product discovery and cloud powered search'),
          link: 'https://aka.ms/CommerceShowcase/discoveryTour',
          description: t(
            'In this tour, you will explore Commerce cloud powered search capabilities to discover products and learn how enriched storytelling capabilities are used to help customers discover products and elevate your brand.'
          ),
        },
        {
          key: 'curbSidePickup',
          picture: curbSidePickupPictureSrc,
          name: t('Curb-side pickup'),
          link: 'https://aka.ms/CommerceShowcase/pickupTour',
          description: t(
            'In this tour, you will explore various contactless buying options such as curb-side pickup and buy online. Find nearby stores using Microsoft Bing services and schedule a designated pick-up time for a seamless experience.'
          ),
        },
        {
          key: 'intelligentRecommendations',
          picture: intelligentRecommendationsPictureSrc,
          name: t('Intelligent recommendations'),
          link: 'https://aka.ms/CommerceShowcase/recommendationsTour',
          description: t(
            'In this tour, you will explore how AI/ML based recommendations allow you to upsell and cross sell products, thereby allowing customers to easily discover products that are relevant to them and drive higher conversions.'
          ),
        },
        {
          key: 'ratingsAndReviews',
          picture: ratingsAndReviewsPictureSrc,
          name: t('Ratings and reviews'),
          link: 'https://aka.ms/CommerceShowcase/reviewsTour',
          description: t(
            'In this tour, you will explore how AI-moderated Ratings and Reviews help improve product selection and accelerate product decisions.'
          ),
        },
        {
          key: 'quickAddToCart',
          picture: quickAddToCartPictureSrc,
          name: t('Quick add to cart'),
          link: 'https://aka.ms/CommerceShowcase/quickAddTour',
          description: t(
            'In this tour, you will explore ways to increase your online conversion by allowing customers to quickly add items to cart without losing their place in the site.'
          ),
        },
        {
          key: 'customerService',
          picture: customerServicePictureSrc,
          name: t('Customer Service'),
          link: 'https://aka.ms/CommerceShowcase/customerServiceTour',
          description: t(
            'In this tour, you will explore how Microsoft’s Power Virtual Agent and Dynamics 365 Customer Service solutions personalize customer interactions and offer a tailored experience via chat bots and customer agents. Experience improved customer service using AI-driven productivity tools that equip agents with the right information at the right time.'
          ),
        },
        {
          key: 'customerVoice',
          picture: customerVoicePictureSrc,
          name: t('Customer Voice'),
          link: 'https://aka.ms/CommerceShowcase/customerVoiceTour',
          description: t(
            'In this tour, you will explore how Microsoft Dynamics 365 Customer Voice helps personalize experiences throughout the customer journey with real-time surveys and follow-up actions.'
          ),
        },
      ],
    },
    {
      key: 'adventureWorksBusiness',
      name: t('Adventure Works Business'),
      tours: [
        {
          key: 'signUp',
          picture: signUpPictureSrc,
          name: t('Sign up to be a business partner'),
          link: 'https://aka.ms/CommerceShowcase/b2bSignUpTour',
          description: t(
            'In this tour, you will explore how your customers can sign up to become business partners and have access to perform business buyer functions including managing users, viewing account credit information and requesting account statements and invoices. The applications can take a bit of time to approve, once you get an email confirmation you can then go back and select “Create an account” within the “Sign in” link at the top of the page.  Make sure to use the same email address you used when filling out the application. After you successfully created an account and have signed in you can now continue with the other business tours below.'
          ),
        },
        {
          key: 'quickOrderEntry',
          name: t('Quick order entry using templates'),
          description: t(
            'In this tour, you will explore how an order template can be created and used to quickly place repeat orders. Ensure you have received the approval for a business account from the first business tour and have created an account before starting this tour.'
          ),
          link: 'https://aka.ms/CommerceShowcase/orderTemplateTour',
          picture: quickOrderEntryPictureSrc,
        },
        {
          key: 'viewAccountInfo',
          name: t('View account credit information'),
          description: t(
            'In this tour, you will explore how your business customers can see their available credit limit and request detailed account statements. Ensure you have received the approval for a business account from the first business tour and have created an account before starting this tour.'
          ),
          link: 'https://aka.ms/CommerceShowcase/accountCreditTour',
          picture: viewAccountInfoPictureSrc,
        },
      ],
    },
  ];
};
