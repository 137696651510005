import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const whitelist = [
  'en-au',
  'nl-be',
  'fr-be',
  'pt-br',
  'en-ca',
  'fr-ca',
  'cs',
  'da',
  'de',
  'et',
  'en',
  'es',
  'fr',
  'en-in',
  'en-ie',
  'is',
  'it',
  'lv',
  'lt',
  'hu',
  'en-my',
  'es-mx',
  'nl',
  'en-nz',
  'nb-no',
  'de-at',
  'pl',
  'de-ch',
  'en-sg',
  'en-za',
  'fr-ch',
  'fi',
  'sv',
  'it-ch',
  'tr',
  'en-gb',
  'ru',
  'ar-ae',
  'ar',
  'th',
  'zh-hans',
  'ja',
];

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    whitelist,
    lowerCaseLng: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `./locales/{{lng}}/lp.translation.json`,
      queryStringParams: { v: 1 },
    },
  });
